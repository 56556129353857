import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import { useQuery } from '@tanstack/react-query';
import { Translate } from '../../../../../i18n/translate';
import { ToggleInput } from '../../../../../components';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import SelectNotifyUsers from './SelectNotifyUsers';
import ApprovalsSerive from '../../approvalsService';

type Props = {
  feature: FeatureType;
  initialSelectedUsers: Array<number>;
  updateNotifications: (newNotifications: Array<number>) => void;
  isPending: boolean;
};

export default function Step4({
  feature,
  initialSelectedUsers,
  updateNotifications,
  isPending,
}: Props) {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState<Array<number>>(initialSelectedUsers);

  const navigate = useNavigate();

  useQuery({
    queryKey: ['getUsersByFeature'],
    queryFn: async () => {
      const data = await ApprovalsSerive.getUsersByFeature(feature);
      return data;
    },
    refetchOnWindowFocus: false,
  });

  return (
    <>
      <div>
        <div className='card px-4 lg:px-14 py-10'>
          <div className='grid gap-1'>
            <Translate
              value='create_approval.receive_notification'
              className='text-neutral-1 text-lg-semibold'
            />
            <Translate
              value='create_approval.receive_notification.description'
              className='text-neutral-3 text-sm-regular'
            />
          </div>
          <div className='pt-6 flex gap-10'>
            <div className='grid gap-1'>
              <Translate
                value='create_approval.manage_notification'
                className='text-neutral-1 text-sm-medium'
              />
              <Translate
                value='create_approval.manage_notification.description'
                className='text-neutral-3 text-xs-regular'
              />
            </div>
            <ToggleInput
              id='manageNotification'
              label=''
              gradient
              checked={dialogOpen || selectedUsers.length > 0}
              buttonAction={() => {
                if (selectedUsers.length === 0) {
                  setDialogOpen(true);
                } else {
                  setSelectedUsers([]);
                }
              }}
            />
          </div>
        </div>
        <div className='flex gap-4 w-full justify-center mt-10'>
          <Button
            type='button'
            severity='contrast'
            className='w-60 inline-flex justify-center'
            onClick={() => navigate(`/manage/approvals?tab=${feature}`)}
          >
            <Translate value='cancel' />
          </Button>
          <Button
            type='submit'
            severity='info'
            className='w-60 inline-flex justify-center gap-2'
            onClick={() => updateNotifications(selectedUsers)}
            disabled={selectedUsers.length === 0 || isPending}
            loading={isPending}
          >
            <Translate value='save' />
          </Button>
        </div>
      </div>
      <Dialog
        visible={dialogOpen}
        onHide={() => setDialogOpen(false)}
        className='w-[30%] scale max-h-[100%] transform scale-100 m-0 !rounded-none full-screen-dialog'
        style={{ height: '100vh', position: 'fixed', top: 0, right: 0 }}
        header={
          <div className='grid gap-1 px-3'>
            <div className='text-lg font-semibold text-neutral-1'>
              <Translate value='notification_user_list_modal_header' />
            </div>
            <div className='text-sm font-regular text-neutral-3'>
              <Translate
                value={'notification_user_list_modal_description'}
                className='!text-sm-regular'
              />
            </div>
          </div>
        }
        headerClassName='border-b border-neutral-surface-subtle'
      >
        <SelectNotifyUsers
          initialSelectedUsers={selectedUsers}
          updateList={(users) => {
            setSelectedUsers(users);
            setDialogOpen(false);
          }}
        />
      </Dialog>
    </>
  );
}
