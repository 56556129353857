import { SortOrder } from 'primereact/datatable';
import apiClient from '../../utils/apiClient';
import { handleApiResponse } from '../../utils/helper';

const ROOT = '/api/customer-management/customers';
const VALIDATE_CLIENT = '/validateOnCreate';
const USER_ROOT = '/api/user-management/users';
const STONEX_CLIENT = '/api/customer-backoffice/customers';
const BRANDING_ROOT = '/api/branding-management/branding/customers';

const ClientService = {
  async getClients(): Promise<Client[]> {
    try {
      const response = await apiClient.get(`${ROOT}`);
      return handleApiResponse<Client[]>(response);
    } catch (error) {
      console.error('Error fetching clients:', error);
      return [];
    }
  },

  async getCustomerHierarchy(customerId: number): Promise<Client | undefined> {
    try {
      const response = await apiClient.get(`${ROOT}/${customerId}/hierarchy`);
      return handleApiResponse<Client>(response);
    } catch (error) {
      console.error('Error fetching clients:', error);
      return;
    }
  },

  async getChildClients(
    childCustomerId: number,
    page = 0,
    rows = 0,
    payload: { [key: string]: string[] | string },
    sortField: string,
    sortOrder: SortOrder,
  ): Promise<ClientResponse | null> {
    try {
      const response = await apiClient.post(
        `${ROOT}/${childCustomerId}/child-customers?page=${page}&size=${rows}&sortBy=${sortField}&direction=${sortOrder === 1 ? 'asc' : 'desc'}`,
        payload,
      );
      return handleApiResponse<ClientResponse>(response);
    } catch (error) {
      console.error('Error fetching clients:', error);
      return null;
    }
  },

  async getClientById(id: string | number): Promise<Client | null> {
    try {
      const response = await apiClient.get(`${ROOT}/${id}`);
      return handleApiResponse<Client | null>(response);
    } catch (error) {
      console.error(`Error fetching client with id ${id}:`, error);
      return null;
    }
  },

  validateOnCreate(client: Client) {
    return apiClient.post(`${ROOT}${VALIDATE_CLIENT}`, client);
  },

  createAccount(client: Client) {
    return apiClient.post(`${ROOT}`, client);
  },

  async updateBranding(formData: FormData, customerId: number): Promise<FileUploadResponse | null> {
    try {
      const response = await apiClient.post(`${BRANDING_ROOT}/${customerId}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      return handleApiResponse(response);
    } catch (err) {
      console.error(`Error uploading  ${customerId}:`, err);
      return null;
    }
  },

  updateCustomer(client: Client, customerId: number) {
    return apiClient.put(`${ROOT}/${customerId}`, client);
  },

  async createUsers(users: User[]) {
    return await apiClient.post(USER_ROOT, users);
  },

  delete(id: number) {
    return apiClient.delete(`${ROOT}/${id}`);
  },

  getCustomerByStonexClientId(stonexClientId: string) {
    return apiClient.get(`${STONEX_CLIENT}/${stonexClientId}/records`);
  },

  async getUsersByCustomerId(customerId: string): Promise<User[] | null> {
    try {
      const response = await apiClient.post(`${USER_ROOT}/customers/${customerId}`, {});
      return handleApiResponse<User[] | null>(response, 'content');
    } catch (error) {
      console.error('Error fetching client with id', error);
      return null;
    }
  },

  async getCustomerBranding(customerId: number): Promise<BrandingResponse[] | null> {
    try {
      const response = await apiClient.get(`${BRANDING_ROOT}/${customerId}`);
      return handleApiResponse<BrandingResponse[] | null>(response);
    } catch (error) {
      console.error('Error fetching client with id', error);
      return null;
    }
  },

  async donwload(customerId: number, type: string): Promise<Blob | null> {
    try {
      const response = await apiClient.get<Blob>(
        `${BRANDING_ROOT}/${customerId}/download?type=${type}`,
        {
          responseType: 'blob',
        },
      );
      return response.data;
    } catch (error) {
      console.error('Error fetching client with id', error);
      return null;
    }
  },

  async downloadCustomer(customerId: number, payload: { [key: string]: string[] | string }) {
    try {
      const response = await apiClient.post(
        `${ROOT}/${customerId}/child-customers/download/csv`,
        payload,
        {
          responseType: 'blob',
        },
      );
      const blob = new Blob([response.data], { type: 'text/csv' });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'customer_data.csv'); // File name for download
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url); //
    } catch (error) {
      console.error('Error fetching client with id', error);
      return null;
    }
  },
};

export default ClientService;
