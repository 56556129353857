import { AddOrEditOrView } from '../../../types';
import AddUser from './AddUser';
import Dashboard from './Dashboard';
import ViewUser from './ViewUser';

const userRoutes = [
  {
    id: 'userDashboard',
    index: true,
    element: <Dashboard />,
  },
  {
    id: 'usersWithCustomer',
    index: true,
    path: ':customerId',
    element: <Dashboard />,
  },
  {
    id: 'addUser',
    path: 'add',
    element: <AddUser />,
    handle: {
      breadcrumb: 'Add New User',
    },
  },
  {
    id: 'Add user to customer',
    path: ':customerId/add',
    element: <AddUser />,
    handle: {
      breadcrumb: 'Add New User',
    },
  },
  {
    id: 'editUser',
    path: 'edit/:userId',
    element: <AddUser />,
    handle: {
      breadcrumb: 'Edit User',
    },
  },
  {
    id: 'viewUser',
    path: 'view/:userId',
    element: <ViewUser mode={AddOrEditOrView.View} />,
    handle: {
      breadcrumb: 'View User',
    },
  },
  {
    id: 'approverUser',
    path: 'approve/:userId',
    element: <ViewUser mode={AddOrEditOrView.Approve} />,
    handle: {
      breadcrumb: 'Approval',
    },
  },
];

export default userRoutes;
