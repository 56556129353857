import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { CustomStepper } from '../../../components';
import { PageHeadContext } from '../../../providers/PageHead';
import { AddOrEditOrView, ClientForms, CustomerType } from '../../../types';
import CustomerDetails from '../CustomerDetails';
import { CustomerOverview } from '../CustomerOverview';
import SiteDetails from '../SiteDetails';
import UserDetails from '../UserDetails';
import ClientService from '../clientService';

interface Step {
  id: string;
  content: React.ReactNode;
}

const AddClient = ({ mode }: { mode: AddOrEditOrView }) => {
  const [activeStep, setActiveStep] = useState<string>('');
  const [visited, setVisited] = useState<Set<string>>(new Set());
  const [visitPermitted, setVisitPermitted] = useState<Set<string>>(new Set());
  const [validationOnStepper, setValidationOnStepper] = useState<Set<string>>(new Set());
  const context = useContext(PageHeadContext);
  const { setPageHeadData } = context;
  const [owningClientId, setOwningClientid] = useState<number | undefined>(undefined);
  const [customerType, setCustomerType] = useState<CustomerType>();
  const [currentEditiCustomer, setCurrentEditiCustomer] = useState<Client>();
  const [customerOveriew, setCustomerOveriew] = useState<CustomerOverviewFormData>();
  const { customerId } = useParams();

  useEffect(() => {
    if (mode === AddOrEditOrView.Edit && customerId) {
      getCutomerById();
    }
  }, [mode, customerId]);

  const getCutomerById = async () => {
    const client = await ClientService.getClientById(customerId!);
    if (client) {
      setCustomerType(client.customerType);
      setCurrentEditiCustomer(client);
    }
  };

  useEffect(() => {
    if (mode === AddOrEditOrView.Edit) {
      setVisitPermitted(
        new Set([
          ClientForms.CustomerOverview,
          ClientForms.CustomerDetails,
          ClientForms.UserDetails,
          ClientForms.SiteDetails,
        ]),
      );
      setVisited(
        new Set([
          ClientForms.CustomerOverview,
          ClientForms.CustomerDetails,
          ClientForms.UserDetails,
          ClientForms.SiteDetails,
        ]),
      );
      setPageHeadData({
        title: currentEditiCustomer?.customerShortName ?? 'editClient.title',
        description: 'editClient.description',
      });
    } else {
      setVisitPermitted(new Set([ClientForms.CustomerOverview]));
      setPageHeadData({
        title: 'addclient.title',
        description: 'addclient.description',
      });
    }
  }, [setPageHeadData, currentEditiCustomer]);

  const jumpToEdit = (step: string) => {
    setActiveStep(step);
  };

  const handleStepperValidation = (formName: ClientForms, isValid: boolean) => {
    setValidationOnStepper((prev: Set<string>) => {
      const newValidations = new Set(prev);
      if (!isValid) {
        newValidations.add(formName);
      } else {
        newValidations.delete(formName);
      }
      return newValidations;
    });
  };

  const steps: Step[] = [
    {
      id: ClientForms.CustomerOverview,
      content: (
        <CustomerOverview
          activeStep={activeStep}
          onSaveAndProceed={() => {
            setVisitPermitted(new Set([...visitPermitted, ClientForms.CustomerDetails]));
            setVisited(new Set([...visited, ClientForms.CustomerOverview]));
            if (mode === AddOrEditOrView.Add && !visited.has(ClientForms.CustomerDetails)) {
              setActiveStep(ClientForms.CustomerDetails);
            } else {
              setActiveStep('');
            }
          }}
          handleStepperValidation={handleStepperValidation}
          jumpToEdit={jumpToEdit}
          setCustomerType={setCustomerType}
          mode={visited?.has(ClientForms.CustomerOverview) ? AddOrEditOrView.Edit : mode}
          visited={visited}
          setCustomerOveriew={setCustomerOveriew}
          currentEditiCustomer={currentEditiCustomer}
        />
      ),
    },
    {
      id: ClientForms.CustomerDetails,
      content: (
        <CustomerDetails
          activeStep={activeStep}
          onSaveAndProceed={() => {
            setVisitPermitted(new Set([...visitPermitted, ClientForms.UserDetails]));
            setVisited(new Set([...visited, ClientForms.CustomerDetails]));
            if (mode === AddOrEditOrView.Add && !visited.has(ClientForms.UserDetails)) {
              setActiveStep(ClientForms.UserDetails);
            } else {
              setActiveStep('');
            }
          }}
          handleStepperValidation={handleStepperValidation}
          jumpToEdit={jumpToEdit}
          setOwningClientid={setOwningClientid}
          mode={visited?.has(ClientForms.CustomerDetails) ? AddOrEditOrView.Edit : mode}
          visited={visited}
          visitPermitted={visitPermitted}
          customerType={customerType}
          customerOveriew={customerOveriew}
          currentEditiCustomer={currentEditiCustomer}
        />
      ),
    },
    {
      id: ClientForms.UserDetails,
      content: (
        <UserDetails
          activeStep={activeStep}
          jumpToEdit={jumpToEdit}
          handleStepperValidation={handleStepperValidation}
          inheritedClientId={owningClientId}
          customerType={customerType}
          mode={mode}
          visitPermitted={visitPermitted}
          visited={visited}
          onSaveAndProceed={() => {
            if (mode === AddOrEditOrView.Add && !visited.has(ClientForms.SiteDetails)) {
              setActiveStep(ClientForms.SiteDetails);
            } else {
              setActiveStep('');
            }
            setVisitPermitted(new Set([...visitPermitted, ClientForms.SiteDetails]));
            setVisited(new Set([...visited, ClientForms.UserDetails]));
          }}
        />
      ),
    },
    {
      id: ClientForms.SiteDetails,
      content: (
        <SiteDetails
          inheritedClientId={owningClientId}
          activeStep={activeStep}
          visited={visited}
          mode={mode}
          visitPermitted={visitPermitted}
          jumpToEdit={jumpToEdit}
          onSaveAndProceed={() => {
            setVisited(new Set([...visited, ClientForms.SiteDetails]));
            setActiveStep('');
          }}
        />
      ),
    },
  ];
  return (
    <>
      <div className='pt-10 mx-auto rounded-md'>
        <CustomStepper
          steps={customerType === CustomerType.ORDERING_CUSTOMER ? steps.slice(0, 3) : steps}
          activeStep={activeStep}
          visited={visited}
          validations={validationOnStepper}
        />
      </div>
    </>
  );
};

export default AddClient;
