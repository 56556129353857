import Dashboard from './Dashboard';
import AddStructure from './Dashboard/AddStructure';

const approvalsRoutes = [
  {
    id: 'approvalsDashboard',
    index: true,
    element: <Dashboard />,
  },
  {
    id: 'addStructure',
    path: 'add-structure',
    handle: {
      breadCrumb: 'Add New Structure',
    },
    element: <AddStructure />,
  },
  {
    id: 'editStructure',
    path: 'edit-structure',
    handle: {
      breadCrumb: 'Edit Structure',
    },
    element: <AddStructure />,
  },
];

export default approvalsRoutes;
