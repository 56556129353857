import { CustomerType, KycStatus, SettlementMethod, SsoProvider, Status } from '../../types';

const CUSTOMER_TYPE_OPTIONS = [
  {
    label: 'Ordering Customer',
    value: CustomerType.ORDERING_CUSTOMER,
  },
  {
    label: 'Ordering Institution',
    value: CustomerType.ORDERING_INSTITUTION,
  },
  {
    label: 'Instructing Institution',
    value: CustomerType.INSTRUCTING_INSTITUTION,
  },
];

const SETTLEMENT_METHOD_OPTIONS = [
  {
    label: 'Direct debit',
    value: SettlementMethod.DIRECT_DEBIT,
  },
  {
    label: 'Wire',
    value: SettlementMethod.WIRE,
  },
  {
    label: 'Sweep',
    value: SettlementMethod.SWEEP,
  },
  {
    label: 'Auto Debit',
    value: SettlementMethod.AUTO_DEBIT,
  },
];

const STATUS_OPTIONS = [
  { label: 'Pending', value: Status.PENDING },
  { label: 'Active', value: Status.ACTIVE },
  { label: 'Inactive', value: Status.INACTIVE },
];

const KYC_INDICATOR_OPTIONS = [
  { label: 'Inactive', value: KycStatus.INACTIVE },
  { label: 'Active', value: KycStatus.ACTIVE },
];

const ROLES_OPTIONS = [{ label: 'Test', value: 'Test' }];

const CUSTOMER_CURRENCY_OPTIONS = [
  { label: 'USD', value: 'USD' },
  { label: 'INR', value: 'INR' },
  { label: 'EUR', value: 'EUR' },
];

const CLIENT_OBJECT_MAPPING = {
  addressId: '',
};

const EMAIL_VALIDATION_REGEX = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

const SSO_PROVIDER_OPTIONS = [{ label: 'Admin', value: SsoProvider.ADMIN }];


export {
  CLIENT_OBJECT_MAPPING,
  CUSTOMER_CURRENCY_OPTIONS,
  CUSTOMER_TYPE_OPTIONS,
  EMAIL_VALIDATION_REGEX,
  KYC_INDICATOR_OPTIONS,
  ROLES_OPTIONS,
  SETTLEMENT_METHOD_OPTIONS,
  SSO_PROVIDER_OPTIONS,
  STATUS_OPTIONS,
};

