import { Button } from 'primereact/button';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { DropdownInput, TextInput } from '../../../components';
import { Translate } from '../../../i18n/translate';
import { useAppDispatch } from '../../../store';
import { sendToast } from '../../../store/slices/main/mainSlice';
import { seletIdpOptions } from '../../../store/slices/refdata/refdataSlice';
import { AddOrEditOrView, ClientForms, CustomerType } from '../../../types';
import { CUSTOMER_TYPE_OPTIONS, STATUS_OPTIONS } from '../clientConstants';

interface CustomerOverviewProps {
  activeStep?: string;
  mode?: AddOrEditOrView;
  visited?: Set<string>;
  currentEditiCustomer?: Client;
  onSaveAndProceed?: () => void;
  jumpToEdit?: (step: string) => void;
  handleStepperValidation?: (formName: ClientForms, isValid: boolean) => void;
  setCustomerType: (customerType: CustomerType) => void;
  setCustomerOveriew: (data: CustomerOverviewFormData) => void;
}

export const CustomerOverview = ({
  activeStep,
  mode,
  visited,
  currentEditiCustomer,
  onSaveAndProceed,
  jumpToEdit,
  handleStepperValidation,
  setCustomerType,
  setCustomerOveriew,
}: CustomerOverviewProps) => {
  const {
    setValue,
    watch,
    register,
    handleSubmit,
    control,
    formState: { errors, isValid },
  } = useForm<CustomerOverviewFormData>();
  const customerType = watch('customerType');
  const idpApplications = useSelector(seletIdpOptions);
  const dispatch = useAppDispatch();

  const saveCustomerDetails = (data: CustomerOverviewFormData) => {
    setCustomerOveriew(data);
    setCustomerType(customerType);
    if (handleStepperValidation) {
      handleStepperValidation(ClientForms.CustomerOverview, true);
    }
    if (onSaveAndProceed) {
      onSaveAndProceed();
    }
  };

  useEffect(() => {
    if (currentEditiCustomer) {
      setFormValues(currentEditiCustomer);
    }
  }, [currentEditiCustomer]);

  const setFormValues = (client: Client) => {
    setValue('status', client.status);
    setValue('customerShortName', client.customerShortName);
    setValue('customerType', client.customerType);
    setValue('customerUrl', client.customerUrl);
    setValue('idpApplicationId', client.idpApplicationId);
    /* TODO: Uncomment when the backend is ready */
    // setValue('enableSso', client.enableSso);
    // setValue('ssoProvider', client.ssoProvider);
  };

  const showErrorToast = () => {
    dispatch(
      sendToast({
        severity: 'error',
        summary: 'client.field.validation.error',
        detail: 'client.field.validation.error.detail',
      }),
    );
  };

  return (
    <>
      <div className='rounded-c8 shadow-c bg-white p-8'>
        <div className='flex justify-between flex-wrap'>
          <div>
            <div className='text-sm-bold'>
              <Translate value='customer.overview.title' />
            </div>
            <div className='text-neutral-3'>
              <Translate value='customer.overview.description' />
            </div>
          </div>
          <div className='flex justify-end items-center'>
            {/* {activeStep === ClientForms.CustomerDetails && (
              <Progress value={progress} currentStep={1} stepLimit={3} />
            )} */}
            {/* <Checkmark type={progress === 100 ? 'success' : 'info'} className='ml-4' /> */}
          </div>
        </div>
        {activeStep === ClientForms.CustomerOverview || mode == AddOrEditOrView.View ? (
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          <form onSubmit={handleSubmit(saveCustomerDetails)}>
            <div className='lg:mx-5 mt-12 mb-6 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5'>
              <div className='col-span-1'>
                <DropdownInput
                  label='customer_type'
                  placeholder='customer_type'
                  name='customerType'
                  isRequired
                  disabled={mode === AddOrEditOrView.Edit || mode === AddOrEditOrView.View}
                  control={control}
                  options={CUSTOMER_TYPE_OPTIONS}
                  rules={{ required: true }}
                  error={errors['customerType']}
                />
              </div>

              <div className='col-span-1 md:col-span-2'>
                <TextInput
                  label='customer_short_name'
                  disabled={!customerType || mode === AddOrEditOrView.View}
                  placeholder='customer_short_name'
                  isRequired
                  formRegister={register('customerShortName', { required: true })}
                  error={errors['customerShortName']}
                />
              </div>

              <div className='col-span-1'>
                <DropdownInput
                  className='block'
                  label='status'
                  placeholder='status'
                  disabled={!customerType || mode === AddOrEditOrView.View}
                  name='status'
                  isRequired
                  control={control}
                  options={STATUS_OPTIONS}
                  rules={{ required: true }}
                  error={errors['status']}
                />
              </div>

              <div className='col-span-1 md:col-span-2'>
                <TextInput
                  label='customer_url'
                  placeholder='customer_url'
                  disabled={!customerType || mode === AddOrEditOrView.View}
                  isRequired
                  formRegister={register('customerUrl', { required: true })}
                  error={errors['customerUrl']}
                />
              </div>

              <hr className='col-span-1 md:col-span-3 border-neutral-border-2' />

              <div>
                <DropdownInput
                  label='idpApplications'
                  placeholder='idpApplications'
                  disabled={!customerType || mode === AddOrEditOrView.View}
                  name='idpApplicationId'
                  isRequired
                  control={control}
                  options={idpApplications}
                  rules={{ required: true }}
                  error={errors['idpApplicationId']}
                />
              </div>
            </div>

            {mode !== AddOrEditOrView.View && (
              <div className='flex items-center justify-center mt-10 gap-5'>
                <Button
                  type='submit'
                  severity='info'
                  onClick={() => {
                    if (handleStepperValidation) {
                      handleStepperValidation(ClientForms.CustomerOverview, isValid);
                    }
                    if (!isValid) {
                      showErrorToast();
                      return;
                    }
                  }}
                >
                  <Translate value='save_and_proceed' />
                </Button>
              </div>
            )}
          </form>
        ) : (
          <div className='flex justify-between mt-4'>
            <Button
              severity='contrast'
              className='!px-14'
              onClick={() => {
                if (jumpToEdit) {
                  jumpToEdit(ClientForms.CustomerOverview);
                }
              }}
            >
              <Translate value={visited?.has(ClientForms.CustomerOverview) ? 'edit' : 'start'} />
            </Button>
            {/* <Progress value={progress} currentStep={1} stepLimit={3} /> */}
          </div>
        )}
      </div>
    </>
  );
};
