import apiClient from '../../../utils/apiClient';
import { handleApiResponse } from '../../../utils/helper';

const CUSTOMER_ROLES = 'api/customer-role-management/customer-roles';
const ROLES = 'api/customer-role-management/roles';
const ASSIGN_USERS = 'api/customer-role-management/roles/assign-users';
const PRIMARY_ROLES = 'api/customer-role-management/primary-roles';
const ACTIVATE_ROLES = 'api/customer-role-management/roles/activate';
const DEACTIVATE_ROLES = 'api/customer-role-management/roles/deactivate';
const DELETE_ROLES = 'api/customer-role-management/customer-roles';

const RoleService = {
  async getCustomerRoles(customerId: string | number | undefined): Promise<RoleResponse> {
    try {
      const response = await apiClient.get(`${CUSTOMER_ROLES}/${customerId}`);
      return handleApiResponse<RoleResponse>(response);
    } catch (error) {
      console.error('Error fetching customer roles:', error);
      return {
        primaryRoles: [],
        customRoles: [],
      };
    }
  },
  async getRoleUsersById(roleId: string | number): Promise<User[] | null> {
    try {
      const response = await apiClient.get(`${ROLES}/${roleId}/users`);
      return handleApiResponse<User[]>(response);
    } catch (error) {
      console.error(`Error fetching users for the roleid - ${roleId}`, error);
      return null;
    }
  },
  async getPrimaryRoles(): Promise<Role[] | null> {
    try {
      const response = await apiClient.get(`${PRIMARY_ROLES}`);
      return handleApiResponse<Role[]>(response);
    } catch (error) {
      console.error('Error fetching primary roles', error);
      return null;
    }
  },
  assignUsersToRole(data: AssignRolePayload) {
    return apiClient.post(`${ASSIGN_USERS}`, data);
  },
  createCustomerRole(data: Role[]) {
    return apiClient.post(`${CUSTOMER_ROLES}`, data);
  },
  deactivateRoles(data: RoleAction) {
    return apiClient.post(`${DEACTIVATE_ROLES}`, data);
  },
  activateRoles(data: RoleAction) {
    return apiClient.post(`${ACTIVATE_ROLES}`, data);
  },
  deleteRole(roleId: number) {
    return apiClient.delete(`${DELETE_ROLES}/${roleId}`);
  },
};

export default RoleService;
