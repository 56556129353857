import { Button } from 'primereact/button';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { DropdownInput, TextInput } from '../../../../../components';
import CountrySelect from '../../../../../components/CountrySelect';
import { Translate } from '../../../../../i18n/translate';
import {
  selectCurrentCustomer,
  selectLoading,
  sendToast,
} from '../../../../../store/slices/main/mainSlice';
import { selectCountries } from '../../../../../store/slices/refdata/refdataSlice';
import { AddOrEditOrView } from '../../../../../types';
import { EMAIL_VALIDATION_REGEX, STATUS_OPTIONS } from '../../../../Client/clientConstants';
import UserService from '../../../userService';

const UserDetails = ({
  onSuccess,
  userId,
  customerId,
  mode,
}: {
  onSuccess: (id: number) => void;
  userId: string | undefined;
  customerId?: number;
  mode: AddOrEditOrView;
}) => {
  const isEdit = !!userId;
  const dispatch = useDispatch();
  const [owningCustomerId, setOwningCustomerId] = useState<number>(1);
  const selectedCountries = useSelector(selectCountries);
  const currentCustomer = useSelector(selectCurrentCustomer);
  const isLoading = useSelector(selectLoading);
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    control,
    reset,
    // getValues,
    formState: { errors, isValid, isDirty },
  } = useForm<UserFormData>();

  useEffect(() => {
    if (currentCustomer && currentCustomer.id) {
      setOwningCustomerId(currentCustomer.id);
    }
    if (customerId) {
      setOwningCustomerId(customerId);
    }
  }, [currentCustomer, customerId]);

  const showToast = (
    severity: 'success' | 'error',
    summary: string,
    detail: string,
    params?: { [key: string]: string },
  ) => {
    dispatch(sendToast({ severity, summary, detail, params }));
  };

  const addUserDetails = async (userformData: UserFormData) => {
    if (!isValid) {
      return;
    }
    if (!isDirty) {
      showToast('error', 'error', 'user.not.dirty.error');
      return;
    }
    const submitData: SubmitUserData = {
      address: {
        street1: userformData.street1,
        street2: userformData.street2,
        city: userformData.city,
        state: userformData.state,
        postalCode: userformData.postalCode,
        country: userformData.country?.code ?? '',
      },
      userIdentity: {
        firstName: userformData.firstName,
        lastName: userformData.lastName,
        mobilePhone: userformData.phone,
        email: userformData.emailId,
        status: userformData.status,
      },
      owningCustomerId: owningCustomerId,
      status: userformData.status,
    };
    if (userId) submitData['id'] = userId;

    const response = await UserService.createUser([submitData]);
    const { status, data } = response;
    if (status !== 200) {
      showToast('error', 'error', 'user.save.error');
      return;
    } else {
      const { result } = data[0];
      if (result.success) {
        showToast(
          'success',
          'success',
          isEdit ? 'userUpdatedSuccessfully' : 'userCreatedSuccessfully',
          {
            email: data[0].data.userIdentity.email,
          },
        );
        if (data[0].data?.id) onSuccess(data[0].data.id);
      } else {
        const { errors } = result;
        showToast('error', 'error', (errors[0] && errors[0].errorTextCode) ?? '', {
          ...errors[0],
        });
        return;
      }
    }
  };

  const handleCancel = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (userId) {
      UserService.getUserById(userId).then((data: User | null) => {
        if (data) {
          const formData: UserFormData = {
            emailId: data.userIdentity.email,
            firstName: data.userIdentity.firstName,
            lastName: data.userIdentity.lastName,
            street1: data.address?.street1 || '',
            street2: data.address?.street2 || '',
            city: data.address?.city || '',
            state: data.address?.state || '',
            postalCode: data.address?.postalCode || '',
            country: selectedCountries.find((c) => c.code === data.address?.country) ?? undefined,
            status: data.status,
            phone: data.userIdentity.mobilePhone,
          };
          reset(formData);
        }
      });
    }
  }, [userId]);

  return (
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    <form onSubmit={handleSubmit(addUserDetails)}>
      <div className='!p-8 card'>
        <div className='flex justify-between flex-wrap'>
          <div>
            <div className='text-sm-bold text-secondary-text'>
              <Translate
                value={`${[AddOrEditOrView.View, AddOrEditOrView.Approve].includes(mode) ? 'user.view.title' : 'add_user.user_details.title'}`}
              />
            </div>
            <div className='text-neutral-3'>
              <Translate value='add_user.user_details.description' />
            </div>
          </div>
          {[AddOrEditOrView.Approve].includes(mode) && (
            <div>
              <Button severity='contrast' className='mr-2'>
                <Translate value='approve' className='ml-1' />
              </Button>
              <Button severity='warning'>
                <Translate value='reject' className='ml-1' />
              </Button>
            </div>
          )}

          {/* {![AddOrEditOrView.View, AddOrEditOrView.Approve].includes(mode) && (
            <div className='flex justify-end items-center'>
              <Progress value={50} currentStep={1} stepLimit={4} />
              <CheckMark className='ml-4' />
            </div>
          )} */}
        </div>
        <div className='lg:mx-5 mt-12 mb-6 grid md:grid-cols-2 lg:grid-cols-3 gap-5'>
          <TextInput
            formRegister={register('emailId', {
              required: true,
              pattern: {
                value: EMAIL_VALIDATION_REGEX,
                message: 'invalid_email_address',
              },
            })}
            error={errors.emailId}
            label='email_id'
            placeholder='email_id'
            disabled={isEdit || [AddOrEditOrView.View, AddOrEditOrView.Approve].includes(mode)}
            isRequired
          />
          <TextInput
            formRegister={register('firstName', { required: true })}
            error={errors.firstName}
            label='first_name'
            placeholder='first_name'
            isRequired
            disabled={[AddOrEditOrView.View, AddOrEditOrView.Approve].includes(mode)}
          />
          <TextInput
            formRegister={register('lastName', { required: true })}
            error={errors.lastName}
            label='last_name'
            placeholder='last_name'
            isRequired
            disabled={[AddOrEditOrView.View, AddOrEditOrView.Approve].includes(mode)}
          />
          <TextInput
            formRegister={register('street1', { required: true })}
            error={errors.street1}
            label='address_line_1'
            placeholder='address_line_1'
            isRequired
            disabled={[AddOrEditOrView.View, AddOrEditOrView.Approve].includes(mode)}
          />
          <TextInput
            formRegister={register('street2')}
            error={errors.street2}
            label='address_line_2'
            placeholder='address_line_2'
            disabled={[AddOrEditOrView.View, AddOrEditOrView.Approve].includes(mode)}
          />
          <TextInput
            formRegister={register('phone', { required: true })}
            error={errors.phone}
            id='phone'
            label='phone'
            placeholder='phone_placeholder'
            isRequired
            disabled={[AddOrEditOrView.View, AddOrEditOrView.Approve].includes(mode)}
          />
          <DropdownInput
            name='status'
            label='status'
            placeholder='status'
            control={control}
            options={STATUS_OPTIONS}
            isRequired
            rules={{ required: true }}
            error={errors.status}
            disabled={[AddOrEditOrView.View, AddOrEditOrView.Approve].includes(mode)}
          />
          <TextInput
            formRegister={register('city')}
            error={errors.city}
            label='city'
            placeholder='city'
            disabled={[AddOrEditOrView.View, AddOrEditOrView.Approve].includes(mode)}
          />
          <TextInput
            formRegister={register('state')}
            error={errors.state}
            label='state'
            placeholder='state'
            disabled={[AddOrEditOrView.View, AddOrEditOrView.Approve].includes(mode)}
          />
          <CountrySelect
            label='country'
            placeholder='country'
            name='country'
            isRequired
            control={control}
            rules={{ required: true }}
            error={errors['country']}
            disabled={[AddOrEditOrView.View, AddOrEditOrView.Approve].includes(mode)}
          />
          <TextInput
            label='zip_code'
            placeholder='zip_code'
            isRequired
            formRegister={register('postalCode', { required: true })}
            error={errors['postalCode']}
            disabled={[AddOrEditOrView.View, AddOrEditOrView.Approve].includes(mode)}
          />
        </div>
      </div>
      {![AddOrEditOrView.View, AddOrEditOrView.Approve].includes(mode) && (
        <div className='flex items-center justify-center mt-10 gap-5'>
          <Button type='button' severity='contrast' className='!px-7' onClick={handleCancel}>
            <Translate value='cancel' />
          </Button>
          <Button
            type='submit'
            severity='info'
            disabled={isLoading}
            className='!px-7'
            onClick={() => {
              if (!isValid) {
                showToast(
                  'error',
                  'client.field.validation.error',
                  'user.field.validation.error.detail',
                );
                return;
              }
            }}
          >
            <Translate value='save_and_proceed' />
          </Button>
        </div>
      )}
    </form>
  );
};

export default UserDetails;
