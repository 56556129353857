import { type ClassValue, clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export const enumToCamelCase = (value: string) =>
  value
    .split('_')
    .map((i) => i.charAt(0).toUpperCase() + i.slice(1).toLocaleLowerCase())
    .join(' ');

export function handleApiResponse<T>(response: any, field = ''): T {
  const { data, result } = response.data;
  if (result.success) {
    if (field) {
      return data[field] as T;
    }
    return data as T;
  } else {
    console.error('API request failed:', result.errors);
    throw new Error(
      (result.errors && result.errors[0] && result.errors[0].errorTextCode) ?? 'API request failed',
    );
  }
}

export const updateColorVariables = (colorConfig: ColorConfig[], colors: ColorsFormData) => {
  colorConfig.forEach(({ name, var: cssVar }) => {
    let colorValue = colors[name];
    if (colorValue) {
      if (!colorValue.startsWith('#')) {
        colorValue = '#' + colorValue;
      }
      document.documentElement.style.setProperty(cssVar as string, colorValue);
    }
  });
};

export const formatDate = (dateString: string) => {
  const d = new Date(dateString);
  const day = String(d.getDate()).padStart(2, '0');
  const month = d.toLocaleString('default', { month: 'short' });
  const year = d.getFullYear();

  return `${day} ${month} ${year}`;
};

export const convertDateFormat = (dateString: string) => {
  const date = new Date(dateString);

  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
  const year = date.getFullYear();

  return `${day}/${month}/${year}`;
};
