import { Button } from 'primereact/button';
import { ColorPicker } from 'primereact/colorpicker';
import { useState } from 'react';
import { TextInput } from '../../../../components';
import { Translate } from '../../../../i18n/translate';
import { AddOrEditOrView } from '../../../../types/enum';

const ColorDetails = ({
  mode,
  colors,
  colorConfig,
  handleChange,
}: {
  mode: AddOrEditOrView;
  colors: ColorsFormData;
  colorConfig: ColorConfig[];
  handleChange: (e: any, name: string) => void;
}) => {
  const [isColorsVisible, setIsColorsVisible] = useState(false);

  return (
    <div className='flex justify-between'>
      <div className='shadow-c w-[90%] rounded-c8 py-8 px-20 flex flex-col gap-4'>
        {isColorsVisible || mode === AddOrEditOrView.View ? (
          <div>
            <div className='w-[80%] flex flex-col gap-2'>
              {colorConfig.map(({ name, placeholder, defaultValue }, index) => (
                <div className='flex items-center justify-between flex-col lg:flex-row' key={index}>
                  <Translate value={placeholder} className='color-label' />
                  <div className='flex items-center gap-4'>
                    <TextInput
                      disabled={mode === AddOrEditOrView.View}
                      placeholder={placeholder}
                      value={colors[name] ?? defaultValue}
                      onChange={(e) => handleChange(e, name)}
                    />
                    <ColorPicker
                      className='border-lighGray border-[.5px] rounded-lg'
                      disabled={mode === AddOrEditOrView.View}
                      value={colors[name] ?? defaultValue}
                      onChange={(e) => handleChange(e, name)}
                    />
                  </div>
                </div>
              ))}
            </div>
            {mode !== AddOrEditOrView.View && (
              <div className='pt-8 text-center'>
                <Button
                  severity='contrast'
                  className='!px-14'
                  onClick={() => {
                    setIsColorsVisible(false);
                  }}
                >
                  <Translate value='return' />
                </Button>
              </div>
            )}
          </div>
        ) : (
          <>
            <div className='text-md-semibold'>
              <Translate value='set_colors' />
            </div>
            <div>
              <Button
                severity='contrast'
                className='!px-14'
                onClick={() => {
                  setIsColorsVisible(true);
                }}
              >
                <Translate value='start' />
              </Button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ColorDetails;
