import { useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Tooltip } from 'primereact/tooltip';
import { AvatarGroup } from 'primereact/avatargroup';
import { Avatar } from 'primereact/avatar';
import { ChevronRight, Close } from '@carbon/icons-react';
import { Translate } from '../../../../../../i18n/translate';
import SelectApproverForm from './SelectApproverForm';
import AmountForm from './AmountForm';

type Props = {
  level: number;
  isEditMode: boolean;
  appStructure: AppStructureType;
  approverCategory: 'USER' | 'ROLE';
  config: AppModelConfig;
  showCloseButton: boolean;
  updateDetails?: (newDetails: AppModelConfig) => void;
  removeLevel?: () => void;
};

function LevelCard({
  level,
  isEditMode,
  appStructure,
  approverCategory,
  config,
  showCloseButton,
  updateDetails = () => null,
  removeLevel = () => null,
}: Props) {
  const [selectApproversDialogOpen, setSelectApproversDialogOpen] = useState(false);

  return (
    <>
      <div id={`level-${level}`} className='card p-0 w-64 relative'>
        {showCloseButton && (
          <button type='button' onClick={removeLevel}>
            <Close className='absolute top-3 right-3 h-6 w-6' />
          </button>
        )}
        <div className='w-full text-center border-b border-neutral-surface-subtle py-3'>
          <span className='text-primary text-lg-semibold'>
            <Translate
              value={appStructure === 'GROUPED' ? 'create_approval.group' : 'create_approval.level'}
            />{' '}
            {level}
          </span>
        </div>
        {appStructure === 'TIERED' && (
          <AmountForm
            isEditMode={isEditMode}
            minThreshold={config.minThreshold || ''}
            maxThreshold={config.maxThreshold || ''}
            setMinThreshold={(value) => updateDetails({ ...config, minThreshold: value })}
            setMaxThreshold={(value) => updateDetails({ ...config, maxThreshold: value })}
          />
        )}
        <div className='px-6 h-48 grid place-content-center place-items-center gap-4'>
          {config.appApprovers.length === 0 ? (
            <>
              <Translate
                value='create_approval.select_approvers.description'
                className='text-neutral-1 text-md-semibold text-center'
              />
              <Button
                type='button'
                severity='info'
                onClick={() => setSelectApproversDialogOpen(true)}
              >
                <Translate value='create_approval.select_approvers' />
              </Button>
            </>
          ) : (
            <div className='flex flex-col items-center px-4 gap-4 w-full'>
              <span className='text-neutral-1 text-md-semibold'>
                {config.appApprovers.length}{' '}
                <Translate
                  value={
                    config.appApprovers.length > 1
                      ? 'create_approval.approvers'
                      : 'create_approval.approver'
                  }
                />
              </span>
              <div className='flex gap-2'>
                <AvatarGroup>
                  <Avatar
                    image='/images/profile.svg'
                    shape='circle'
                    className='w-10 h-10 bg-avatar-background-light border border-background text-xs-medium text-neutral-3'
                  />
                  {config.appApprovers.length > 1 && (
                    <Avatar
                      label={`+${config.appApprovers.length - 1}`}
                      shape='circle'
                      className='w-10 h-10 border border-background text-xs-medium text-neutral-3'
                    />
                  )}
                </AvatarGroup>
                {isEditMode && (
                  <Button
                    type='button'
                    onClick={() => setSelectApproversDialogOpen(true)}
                    className='w-10 h-10 rounded-xl border border-dashed border-neutral-5 grid place-content-center'
                  >
                    <ChevronRight size={18} className='text-primary-gradient-from' />
                  </Button>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
      {appStructure === 'TIERED' && (
        <Tooltip
          target={`#level-${level}`}
          content='Please enter the start and end amount ranges. The currency has been set to USD in the earlier step'
          position='left'
          showDelay={300}
        />
      )}
      <Dialog
        visible={selectApproversDialogOpen}
        onHide={() => setSelectApproversDialogOpen(false)}
        className='w-[30%] scale max-h-[100%] transform scale-100 m-0 !rounded-none full-screen-dialog'
        style={{ height: '100vh', position: 'fixed', top: 0, right: 0 }}
        header={
          <div className='grid gap-1 px-3'>
            <div className='text-lg font-semibold text-neutral-1'>
              <Translate value='create_approval.select_approvers' />
            </div>
            <div className='text-sm font-regular text-neutral-3'>
              <Translate
                value={
                  approverCategory === 'USER'
                    ? 'create_approval.select_users_dialog_description'
                    : 'create_approval.select_roles_dialog_description'
                }
                className='!text-sm-regular'
              />
            </div>
          </div>
        }
        headerClassName='border-b border-neutral-surface-subtle'
      >
        <SelectApproverForm
          isEditMode={isEditMode}
          approvers={config.appApprovers}
          approverCategory={approverCategory}
          initialReqAppCount={config.reqAppCount}
          updateDetails={(approvers, updatedReqAppCount) => {
            updateDetails({
              ...config,
              appApprovers: approvers,
              reqAppCount: updatedReqAppCount,
            });
            setSelectApproversDialogOpen(false);
          }}
        />
      </Dialog>
    </>
  );
}

export default LevelCard;
