import { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Dialog, type DialogProps } from 'primereact/dialog';
import { FilterMatchMode } from 'primereact/api';
import { Filter } from '@carbon/icons-react';
import { PaginatorTemplate, SuccessIcon, TableSearch, WarningIcon } from '../../../../components';
import { Translate, TranslateWithValues } from '../../../../i18n/translate';
import ApprovalsSerive from '../approvalsService';
import { sendToast } from '../../../../store/slices/main/mainSlice';

type Props = {
  action: 'delete' | 'deactivate';
  approvalStructure: ApprovalStructure;
  feature: FeatureType;
};

export default function ActionConfirmPopup({
  action,
  approvalStructure,
  feature,
  ...props
}: DialogProps & Props) {
  const [globalFilterValue, setGlobalFilterValue] = useState('');
  const [filters, setFilters] = useState({
    global: { value: '', matchMode: FilterMatchMode.CONTAINS },
  });
  const [popupMode, setPopupMode] = useState<'table' | 'confirm-again' | 'success'>(
    feature === 'PAYMENT' ? 'table' : 'confirm-again',
  );

  const queryClient = useQueryClient();

  const dispatch = useDispatch();

  const onGlobalFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const _filters = { ...filters };
    _filters['global'].value = value;
    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const tableQuery = useQuery({
    queryKey: ['payment_list'],
    queryFn: async () => {
      const data = await new Promise<Array<PaymentData>>((resolve) => resolve([]));
      return data;
    },
  });

  const mutation = useMutation({
    mutationFn: async () => {
      let response;
      if (action === 'delete') {
        // response = await ApprovalsSerive.deletePaymentApprovalStructure(approvalStructure.id);
      } else {
        response = await ApprovalsSerive.deactivateApprovalStructure([approvalStructure.id]);
      }
      return response;
    },
    onSuccess: () => {
      setPopupMode('success');
      queryClient.invalidateQueries({ queryKey: ['approvals_list', feature] });
    },
    onError: () => {
      dispatch(
        sendToast({
          summary: 'error',
          severity: 'error',
          detail: 'approvals.deactivate_error',
        }),
      );
    },
  });

  if (popupMode === 'success') {
    return (
      <Dialog
        {...props}
        draggable={false}
        visible
        className='rounded-xl confirm-dialog w-[400px]'
        header={
          <div className='grid gap-4 px-5 py-5'>
            <div className='w-fit'>
              <SuccessIcon />
            </div>
            <div className='grid gap-2'>
              <h2 className='text-midnightBlue text-sm-bold'>
                <TranslateWithValues
                  value={
                    action === 'delete'
                      ? 'approvals.delete_success_header'
                      : 'approvals.deactivate_success_header'
                  }
                  params={{ name: approvalStructure.modelName }}
                />
              </h2>
              <p className='text-xs-medium text-unselected'>
                <Translate
                  value={
                    action === 'delete'
                      ? 'approvals.delete_success_description'
                      : 'approvals.deactivate_success_description'
                  }
                />
              </p>
            </div>
          </div>
        }
      >
        <Button
          severity='info'
          className='grid place-items-center w-full'
          onClick={() => props.onHide()}
        >
          <Translate value='done' />
        </Button>
      </Dialog>
    );
  }

  if (popupMode === 'confirm-again') {
    return (
      <Dialog
        {...props}
        draggable={false}
        visible
        className='rounded-xl confirm-dialog w-[400px]'
        header={
          <div className='grid gap-4 px-5 py-5'>
            <div className='w-fit'>
              <WarningIcon />
            </div>
            <div className='grid gap-2'>
              <h2 className='text-midnightBlue text-sm-bold'>
                <TranslateWithValues
                  value='approvals.deactivate_confirm_again_header'
                  params={{ name: approvalStructure.modelName }}
                />
              </h2>
              <p className='text-xs-medium text-unselected'>
                <Translate value='approvals.deactivate_confirm_again_description' />
              </p>
            </div>
          </div>
        }
      >
        <div className='grid gap-4'>
          <Button
            severity='info'
            className='flex gap-2 justify-center'
            onClick={() => mutation.mutate()}
            loading={mutation.isPending}
          >
            <Translate value='confirm' />
          </Button>
          <Button
            severity='secondary'
            className='grid place-items-center'
            onClick={() => props.onHide()}
          >
            <Translate value='cancel' />
          </Button>
        </div>
      </Dialog>
    );
  }

  return (
    <Dialog
      {...props}
      draggable={false}
      visible
      className='rounded-xl confirm-dialog min-w-[335px] w-full h-full px-28'
      header={
        <div className='grid gap-4 px-16 py-5'>
          <div className='w-fit'>
            <WarningIcon />
          </div>
          <div className='grid gap-2'>
            <h2 className='text-midnightBlue text-sm-bold'>
              <Translate
                value={
                  action === 'delete'
                    ? 'approvals.delete_confirm_header'
                    : 'approvals.deactivate_confirm_header'
                }
              />
            </h2>
            <p className='text-xs-medium text-unselected'>
              <Translate
                value={
                  action === 'delete'
                    ? 'approvals.delete_confirm_description'
                    : 'approvals.deactivate_confirm_description'
                }
              />
            </p>
          </div>
        </div>
      }
    >
      <div className='px-14 flex flex-col'>
        <div className='flex-grow overflow-auto'>
          {tableQuery.isLoading ? (
            <div className='text-center'>Loading...</div>
          ) : !tableQuery.data || tableQuery.data.length === 0 ? (
            <Translate
              value='no_payment_data'
              className='block text-center text-neutral-2 text-sm-medium'
            />
          ) : (
            <DataTable
              size='large'
              loading={tableQuery.isFetching}
              header={
                <div className='flex justify-between'>
                  <div className='flex flex-col gap-1'>
                    <div className='text-neutral-1 text-lg-semibold'>
                      <Translate value='payment_table.heading' />
                      <span className='ml-1 text-xs-medium px-[8px] py-[2px] rounded-[16px] bg-neutral-surface-dark'>
                        {tableQuery.data?.length}
                      </span>
                    </div>
                    <Translate
                      value='payment_table.subtitle'
                      className='text-neutral-3 text-sm-regular'
                    />
                  </div>
                  <div className='flex justify-around gap-2 items-center'>
                    <TableSearch
                      globalFilterValue={globalFilterValue}
                      onGlobalFilterChange={onGlobalFilterChange}
                    />
                    <div>
                      <Button severity='secondary' className='p-button-text'>
                        <Filter /> <Translate value='filterLabel' className='ml-1' />
                      </Button>
                    </div>
                    <Button severity='info' className='p-button-text'>
                      <Translate value='downloadLabel' />
                    </Button>
                  </div>
                </div>
              }
              value={tableQuery.data}
              paginator
              first={0}
              rows={10}
              totalRecords={tableQuery.data?.length}
              paginatorTemplate={PaginatorTemplate}
              dataKey='paymentId'
              globalFilterFields={[
                'paymentId',
                'dateCreated',
                'buyCurrency',
                'buyAmount',
                'sellCurrency',
                'sellAmount',
                'rate',
                'valueDate',
              ]}
              filters={filters}
            >
              <Column
                field='paymentId'
                header={<Translate value='payment_tabel.header.paymentId' />}
              />
              <Column
                field='dateCreated'
                header={<Translate value='payment_tabel.header.dateCreated' />}
              />
              <Column
                field='buyCurrency'
                header={<Translate value='payment_tabel.header.buyCurrency' />}
              />
              <Column
                field='buyAmount'
                header={<Translate value='payment_tabel.header.buyAmount' />}
              />
              <Column
                field='sellCurrency'
                header={<Translate value='payment_tabel.header.sellCurrency' />}
              />
              <Column
                field='sellAmount'
                header={<Translate value='payment_tabel.header.sellAmount' />}
              />
              <Column field='rate' header={<Translate value='payment_tabel.header.rate' />} />
              <Column
                field='valueDate'
                header={<Translate value='payment_tabel.header.valueDate' />}
              />
            </DataTable>
          )}
        </div>
        <div className='grid place-items-center pt-8'>
          <Button
            severity='info'
            className='w-36 flex gap-2 justify-center'
            onClick={() => {
              if (action === 'delete') {
                mutation.mutate();
              } else {
                setPopupMode('confirm-again');
              }
            }}
            loading={mutation.isPending}
          >
            <Translate value='confirm' />
          </Button>
        </div>
      </div>
    </Dialog>
  );
}
