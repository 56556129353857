import { Button } from 'primereact/button';
import { Translate } from '../../../../../i18n/translate';

const StructuresAssigned = () => {
  return (
    <>
      <div className='!p-8 card'>
        <div className='grid grid-cols-2'>
          <div>
            <div className='text-sm-bold text-secondary-text'>
              <Translate value='add_user.structures_assigned.title' />
            </div>
            <div className='text-neutral-3'>
              <Translate value='add_user.structures_assigned.description' />
            </div>
          </div>
          {/* <div className='flex justify-end items-center'>
            <Progress value={50} currentStep={3} stepLimit={3} />
            <CheckMark className='ml-4' />
          </div> */}
        </div>
        <div className='p-5 bg-dark-grey my-[200px] mx-auto w-fit'>
          <Translate value='pending_feature' />
        </div>
      </div>
      <div className='flex items-center justify-center mt-10 gap-5'>
        <Button severity='contrast' className='!px-7'>
          <Translate value='cancel' />
        </Button>
        <Button severity='info' className='!px-7'>
          <Translate value='save_and_proceed' />
        </Button>
      </div>
    </>
  );
};

export default StructuresAssigned;
