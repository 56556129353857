import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { CustomTabView } from '../../../../components';
import { PageHeadContext } from '../../../../providers/PageHead';
import { AddOrEditOrView } from '../../../../types';
import RolesAssignedContent from './RolesAssigned';
import Settings from './Settings';
import StructuresAssigned from './StructuresAssigned.tsx';
import UserDetails from './UserDetails';

const AddUser = () => {
  const { userId: paramUserId } = useParams<{ userId?: string }>();
  const { customerId: paramCustomerId } = useParams<{ customerId?: string }>();
  const isEdit = !!paramUserId;
  const [activeTab, setActiveTab] = useState(0);
  const [userId, setUserId] = useState<string | undefined>(paramUserId);
  const context = useContext(PageHeadContext);
  const { setPageHeadData } = context;

  const handleTabChange = (index: number) => {
    setActiveTab(index);
  };

  const handleSuccess = (id: number) => {
    setUserId(id.toString());
    handleTabChange(1);
  };

  useEffect(() => {
    setPageHeadData({
      title: 'user.list.title',
      description: 'user.list.description',
      className: 'pb-[16px] pr-[60px]',
    });
  }, []);

  const tabs = [
    {
      header: 'user_details.title',
      content: (
        <UserDetails
          onSuccess={handleSuccess}
          userId={userId}
          mode={AddOrEditOrView.Add}
          customerId={paramCustomerId ? parseInt(paramCustomerId) : undefined}
        />
      ),
    },
    {
      header: 'add_user.roles_assigned',
      content: <RolesAssignedContent isEdit={isEdit} userId={userId} roleType='primary' />,
      isDisabled: !userId,
    },
    {
      header: 'aoboRoles',
      content: <RolesAssignedContent isEdit={isEdit} userId={userId} roleType='aobo' />,
      isDisabled: !userId,
    },
    {
      header: 'add_user.structures_assinged',
      content: <StructuresAssigned />,
      isDisabled: !userId,
    },
    { header: 'settings', content: <Settings />, isDisabled: !userId },
  ];

  return <CustomTabView tabs={tabs} initialIndex={activeTab} />;
};

export default AddUser;
