import { InputSwitch } from 'primereact/inputswitch';
import { translateWithValues } from '../../i18n/translate';
import { cn } from '../../utils/helper';

interface ToggleInputProps {
  id: string;
  label: string;
  ariaDescribedBy?: string;
  isRequired?: boolean;
  checked: boolean;
  className?: string;
  gradient?: boolean;
  buttonAction: () => void;
}

const ToggleInput = ({
  id,
  label,
  isRequired,
  ariaDescribedBy,
  checked,
  className,
  gradient = false,
  buttonAction,
}: ToggleInputProps) => (
  <div className={className ? className : 'flex flex-col gap-1'}>
    <label htmlFor={id}>
      {translateWithValues(label)} {isRequired && <span className='text-error-1'>*</span>}
    </label>
    <InputSwitch
      id={id}
      checked={checked}
      aria-describedby={ariaDescribedBy}
      onClick={buttonAction}
      pt={
        gradient
          ? {
              slider: ({ props }: any) => ({
                className: cn({
                  'bg-neutral-surface-dark': !props.checked,
                  'bg-gradient-to-t from-primary-gradient-from to-primary-gradient-to before:transform before:translate-x-5':
                    props.checked,
                }),
              }),
            }
          : {}
      }
    />
  </div>
);

export default ToggleInput;
