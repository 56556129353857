import { Dialog, type DialogProps } from 'primereact/dialog';
import { translateWithValues } from '../../../../i18n/translate';

type Props = {
  approvalDetails?: ApprovalStructure;
};

function ViewApproversDialog({ approvalDetails, ...props }: DialogProps & Props) {
  console.log(approvalDetails);
  return (
    <Dialog
      visible
      className='w-[30%] scale max-h-[100%] transform scale-100 m-0 !rounded-none full-screen-dialog'
      style={{ height: '100vh', position: 'fixed', top: 0, right: 0 }}
      header={
        <div className='grid gap-1 px-3'>
          <div className='text-lg font-semibold text-neutral-1'>
            {translateWithValues('create_approval.select_approvers')}
          </div>
          <div className='text-sm font-regular text-neutral-3'>
            {translateWithValues('create_approval.select_approvers')}
          </div>
        </div>
      }
      headerClassName='border-b border-neutral-surface-subtle'
      {...props}
    ></Dialog>
  );
}

export default ViewApproversDialog;
