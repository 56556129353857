import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { useAppDispatch } from '../../../../store';
import { fetchUsersForRole, selectRoleUsers } from '../../../../store/slices/role/roleSlice';
import RoleDefinitionCard from './RoleDefinitionCard';
import RoleUsers from './RoleUsers';

const PrimaryUsersView = () => {
  const dispatch = useAppDispatch();
  const roleUsers = useSelector(selectRoleUsers);
  const location = useLocation();
  const role = location.state ? location.state.role : null;

  useEffect(() => {
    getUsers();
  }, [role]);

  const getUsers = () => {
    if (role) {
      dispatch(fetchUsersForRole(role.id));
    }
  };

  if (!role) {
    return <></>;
  }

  return (
    <>
      <RoleDefinitionCard role={role} />
      {roleUsers && roleUsers?.length > 0 && (
        <RoleUsers users={roleUsers} roleId={role?.id} roleName={role?.roleName} />
      )}
    </>
  );
};

export default PrimaryUsersView;
