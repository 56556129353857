import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../rootReducer';
import { fetchAppRefdata, fetchCurrency } from './actions';
import countries from './country.list';

interface Currency {
  code: string;
  currencyCode: string | null;
}

interface RefDataState {
  countries: Country[];
  currencies: Currency[];
  languages: string[];
  provinceList: string[];
  customerCurrencies: CurrencyConfiguration[];
  appMetaData: MetaData | null;
}

export const initialState: RefDataState = {
  countries: countries,
  currencies: countries,
  languages: [],
  provinceList: [],
  customerCurrencies: [],
  appMetaData: null,
};

const refdataSlice = createSlice({
  name: 'refdata',
  initialState,
  reducers: {
    reset: (state: RefDataState) => {
      state.countries = [];
      state.currencies = [];
      state.customerCurrencies = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCurrency.fulfilled, (state, action) => {
      state.customerCurrencies = action.payload;
    });
    builder.addCase(fetchAppRefdata.fulfilled, (state, action) => {
      state.appMetaData = action.payload;
    });
  },
});

export const { reset } = refdataSlice.actions;
export const selectCountries = (state: RootState) => state.refdata.countries;
export const selectCurrencies = (state: RootState) => state.refdata.currencies;
export const seletIdpOptions = (state: RootState) =>
  state.refdata.appMetaData?.idpApplications.map((app) => ({
    label: app.applicationName,
    value: app.id,
  })) || [];
export const selectCustomerCurrencies = (state: RootState) => state.refdata.customerCurrencies;
export const selectRefDataCache = (state: RootState) => state.refdata;
export default refdataSlice.reducer;
