import { Button } from 'primereact/button';
import { InputSwitch } from 'primereact/inputswitch';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { CurrencySelect, DropdownInput, TextInput } from '../../../components';
import CountrySelect from '../../../components/CountrySelect';
import getCountryISO2 from '../../../components/CountrySelect/isoncCodeMapping';
import { Translate } from '../../../i18n/translate';
import { useAppDispatch } from '../../../store';
import {
  selectCurrentCustomer,
  selectLoading,
  sendToast,
} from '../../../store/slices/main/mainSlice';
import {
  selectCountries,
  selectCustomerCurrencies,
} from '../../../store/slices/refdata/refdataSlice';
import { AddOrEditOrView, ClientForms, CustomerType } from '../../../types';
import {
  EMAIL_VALIDATION_REGEX,
  KYC_INDICATOR_OPTIONS,
  SETTLEMENT_METHOD_OPTIONS,
} from '../clientConstants';
import ClientService from '../clientService';

interface CustomerDetailsProps {
  activeStep?: string;
  mode?: AddOrEditOrView;
  customerType: CustomerType | undefined;
  customerOveriew: CustomerOverviewFormData | undefined;
  currentEditiCustomer?: Client;
  visited?: Set<string>;
  visitPermitted?: Set<string>;
  onSaveAndProceed?: () => void;
  setOwningClientid?: (owningClientId: number) => void;
  jumpToEdit?: (step: string) => void;
  handleStepperValidation?: (formName: ClientForms, isValid: boolean) => void;
}

const CustomerDetails = ({
  activeStep,
  onSaveAndProceed,
  jumpToEdit,
  setOwningClientid,
  mode = AddOrEditOrView.Add,
  visited,
  visitPermitted,
  handleStepperValidation,
  customerType,
  customerOveriew,
  currentEditiCustomer,
}: CustomerDetailsProps) => {
  const [editClient, setEditClient] = useState<Client | null>(null);
  const countriesList = useSelector(selectCountries);
  const currencies = useSelector(selectCustomerCurrencies);
  const { customerId } = useParams();
  const dispatch = useAppDispatch();
  const {
    setValue,
    register,
    handleSubmit,
    control,
    watch,
    unregister,
    formState: { errors, touchedFields, dirtyFields, isValid },
  } = useForm<CustomerDetailsFormData>();

  const formValues = watch();
  const [, setProgress] = useState<number>(0);
  const loggedInCustomer = useSelector(selectCurrentCustomer);
  const [stonexAPIFetching, setStonexAPIFetching] = useState(false);
  const isLoading = useSelector(selectLoading);

  const calculateProgress = (): number => {
    const formFields = Object.keys(control._fields);
    const totalFields = formFields.length;
    const validAndTouchedFields = formFields.filter(
      (fieldName) =>
        (touchedFields[fieldName as keyof CustomerDetailsFormData] ||
          dirtyFields[fieldName as keyof CustomerDetailsFormData]) &&
        !errors[fieldName as keyof CustomerDetailsFormData],
    ).length;

    return totalFields > 0 ? Math.round((validAndTouchedFields / totalFields) * 100) : 0;
  };

  useEffect(() => {
    setProgress(calculateProgress());
  }, [formValues, touchedFields, dirtyFields, errors]);

  useEffect(() => {
    if (!customerType) {
      return;
    }

    if (customerType !== CustomerType.INSTRUCTING_INSTITUTION) {
      unregister(['stoneXId']);
    }

    if (customerType !== CustomerType.ORDERING_CUSTOMER) {
      unregister(['customerIdentifier', 'bankType', 'bankABA', 'accountNumber']);
    }
    if (customerType === CustomerType.INSTRUCTING_INSTITUTION) {
      unregister(['doddFrank']);
      unregister(['email']);
      unregister(['phone']);
    }
  }, [customerType, unregister]);

  useEffect(() => {
    if (currentEditiCustomer) {
      setEditClient(currentEditiCustomer);
      setFormValues(currentEditiCustomer);
    }
  }, [currentEditiCustomer]);

  const setFormValues = (client: Client) => {
    setValue('street1', client.address.street1);
    setValue('street2', client.address.street2);
    setValue('city', client.address.city);
    setValue('state', client.address.state);
    setValue('postalCode', client.address.postalCode);
    setValue('customerLegalName', client.customerLegalName);
    setValue(
      'country',
      countriesList.find(
        (country) =>
          (customerType == CustomerType.INSTRUCTING_INSTITUTION
            ? getCountryISO2(country.code)
            : country.code) === client.address.country,
      ) ?? undefined,
    );
    setValue('phone', client.phone);
    setValue('email', client.email);
    setValue('doddFrank', client.isDoddFrank);
    setValue('customerIdentifier', client.customerIdentifier);
    if (client.settlementMethods && client.settlementMethods[0]) {
      setValue('settlementMethod', client.settlementMethods[0]);
    }
    setValue('stoneXId', client.stoneXId);
    const currency = currencies.find((currency) => currency.isocode === client.currency);
    if (currency) {
      setValue('customerCurrency', currency.isocode);
    }
    if (client.bankInfos && client.bankInfos[0]) {
      setValue('accountNumber', client.bankInfos[0].accountNumber);
      setValue('bankABA', client.bankInfos[0].routingCode);
      setValue('bankType', client.bankInfos[0].codeType);
    }
    setValue('kycStatus', client.kycStatus);
  };

  const getStonexCustomerData = async (stonexClientId: string) => {
    const { data, status } = await ClientService.getCustomerByStonexClientId(stonexClientId);
    if (status !== 200) {
      showToast('error', 'error', 'stonex.client.fetch.error.toast.desc');
      return;
    } else if (!data.result.success || data.data.value.length === 0) {
      showToast('error', 'error', 'stonex.client.fetch.notfound.toast.desc');
    } else {
      const clients = data.data.value as StonexClient[];
      if (Array.isArray(clients) && clients.length > 0) {
        const client = clients[0];
        const { Companyconfig } = client;
        const { Basicinfo } = client;
        const { Address } = Basicinfo;
        const country = countriesList.find(
          (country) => getCountryISO2(country.code) === Address.CountryCode,
        );
        setValue('customerLegalName', Basicinfo.Fullname);
        setValue('street1', Address.AddressLines[0]);
        setValue('street2', Address.AddressLines[1]);
        setValue('city', Address.City);
        setValue('state', Address.State);
        setValue('postalCode', Address.PostalCode);
        setValue('country', country);
        setValue('email', Basicinfo.Email);
        setValue('doddFrank', Companyconfig.DoddFrank);
      }
      setStonexAPIFetching(false);
    }
  };

  const saveCustomerDetails = async (customerData: CustomerDetailsFormData) => {
    if (!isValid) {
      return;
    }
    const submitData: any = {
      ...customerOveriew,
      ...customerData,
      customerIdentifier: customerData.customerIdentifier,
      stree1: undefined,
      street2: undefined,
      city: undefined,
      state: undefined,
      country: undefined,
      customerCurrency: undefined,
      isDoddFrank: customerData.doddFrank,
      settlementMethods: [customerData.settlementMethod],
      currency: customerData.customerCurrency ?? '',
      address: {
        street1: customerData.street1,
        street2: customerData.street2,
        city: customerData.city,
        state: customerData.state,
        postalCode: customerData.postalCode,
        country: customerData.country?.code ?? '',
      },
      bankInfos: [],
      tenantId: 1,
    };
    /* current customer - logged in customer */
    if (loggedInCustomer) {
      submitData.owningCustomerId = loggedInCustomer.id;
    }
    if (mode === AddOrEditOrView.Add && customerId) {
      submitData.owningCustomerId = customerId; /* Add child client */
    }

    if (customerType !== CustomerType.INSTRUCTING_INSTITUTION) {
      submitData.bankInfos = [
        {
          accountNumber: customerData.accountNumber,
          routingCode: customerData.bankABA,
          codeType: customerData.bankType,
        },
      ];
    }
    if (mode == AddOrEditOrView.Add) {
      const { data, status } = await ClientService.createAccount(submitData as Client);
      if (status !== 200) {
        showToast('error', 'error', 'client.creation.error.toast.desc');
        return;
      } else {
        const { result } = data;
        if (result.success) {
          showToast('success', 'success', 'client.details.saved.toast.desc');
        } else {
          const { errors } = result;
          showToast('error', 'error', (errors[0] && errors[0].errorTextCode) ?? '', {
            ...errors[0],
            ...submitData,
          });
          return;
        }
      }
      if (data?.data) {
        setEditClient(data.data as Client);
        if (setOwningClientid) {
          setOwningClientid(data.data.id as number);
        }
      }
    } else {
      if (!editClient?.id) {
        return;
      }
      const clientId: number = editClient?.id;
      const updateClient = { ...submitData, id: clientId };
      const { status, data } = await ClientService.updateCustomer(
        updateClient as Client,
        editClient?.id,
      );
      if (status !== 200) {
        showToast('error', 'error', 'client.updation.error.toast.desc');
        return;
      } else {
        const { result } = data;
        if (result.success) {
          showToast('success', 'success', 'client.details.saved.toast.desc');
        } else {
          const { errors } = result;
          showToast('error', 'error', (errors[0] && errors[0].errorTextCode) ?? '', {
            ...errors[0],
            ...submitData,
          });
          return;
        }
      }
      if (setOwningClientid) {
        setOwningClientid(clientId);
      }
    }
    if (onSaveAndProceed) {
      onSaveAndProceed();
    }
  };

  const showToast = (
    severity: 'success' | 'error',
    summary: string,
    detail: string,
    params?: { [key: string]: string },
  ) => {
    dispatch(sendToast({ severity, summary, detail, params }));
  };

  return (
    <>
      <div className='rounded-c8 shadow-c bg-white p-8'>
        <div className='flex justify-between flex-wrap'>
          <div>
            <div className='text-sm-bold'>
              <Translate value='customer.title' />
            </div>
            <div className='text-neutral-3'>
              <Translate value='customer.description' />
            </div>
          </div>
          <div className='flex justify-end items-center'>
            {/* {activeStep === ClientForms.CustomerDetails && (
              <Progress value={progress} currentStep={1} stepLimit={3} />
            )} */}
            {/* <Checkmark type={progress === 100 ? 'success' : 'info'} className='ml-4' /> */}
          </div>
        </div>
        {(activeStep === ClientForms.CustomerDetails || mode == AddOrEditOrView.View) &&
        customerType ? (
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          <form onSubmit={handleSubmit(saveCustomerDetails)}>
            <div className='lg:mx-5 mt-12 mb-6 grid md:grid-cols-2 lg:grid-cols-3 gap-5'>
              {customerType === CustomerType.INSTRUCTING_INSTITUTION && (
                <div className='col-span-3 flex flex-row items-end'>
                  <TextInput
                    type='button'
                    className='w-1/3'
                    label='stonexClientId'
                    disabled={mode == AddOrEditOrView.Edit || mode == AddOrEditOrView.View}
                    placeholder='stonexClientId'
                    isRequired
                    formRegister={register('stoneXId', { required: true })}
                    error={errors['stoneXId']}
                  />

                  <Button
                    className='inline h-[43px] ml-0'
                    severity='info'
                    label='search'
                    disabled={mode == AddOrEditOrView.Edit || mode == AddOrEditOrView.View}
                    onClick={(event) => {
                      setStonexAPIFetching(true);
                      event.preventDefault();
                      getStonexCustomerData(formValues.stoneXId);
                    }}
                  ></Button>
                </div>
              )}

              <div className='col-span-2'>
                <TextInput
                  label='customer_name'
                  disabled={
                    stonexAPIFetching ||
                    customerType === CustomerType.INSTRUCTING_INSTITUTION ||
                    mode == AddOrEditOrView.View
                  }
                  placeholder='customer_name'
                  isRequired
                  formRegister={register('customerLegalName', { required: true })}
                  error={errors['customerLegalName']}
                />
              </div>

              <div className='col-span-1'>
                <DropdownInput
                  label='kycStatus'
                  placeholder='kycStatus'
                  disabled={stonexAPIFetching || mode == AddOrEditOrView.View}
                  name='kycStatus'
                  isRequired
                  control={control}
                  options={KYC_INDICATOR_OPTIONS}
                  rules={{ required: true }}
                  error={errors['kycStatus']}
                />
              </div>

              <TextInput
                label='address_line_1'
                disabled={
                  stonexAPIFetching ||
                  customerType === CustomerType.INSTRUCTING_INSTITUTION ||
                  mode == AddOrEditOrView.View
                }
                placeholder='address_line_1'
                isRequired
                formRegister={register('street1', { required: true })}
                error={errors['street1']}
              />
              <TextInput
                label='address_line_2'
                disabled={
                  stonexAPIFetching ||
                  customerType === CustomerType.INSTRUCTING_INSTITUTION ||
                  mode == AddOrEditOrView.View
                }
                placeholder='street2'
                formRegister={register('street2')}
              />

              <TextInput
                label='city'
                disabled={
                  stonexAPIFetching ||
                  customerType === CustomerType.INSTRUCTING_INSTITUTION ||
                  mode == AddOrEditOrView.View
                }
                placeholder='city'
                isRequired
                formRegister={register('city', { required: true })}
                error={errors['city']}
              />
              <TextInput
                label='state'
                disabled={
                  stonexAPIFetching ||
                  customerType === CustomerType.INSTRUCTING_INSTITUTION ||
                  mode == AddOrEditOrView.View
                }
                placeholder='state'
                formRegister={register('state')}
                error={errors['state']}
              />
              <TextInput
                label='zip_code'
                disabled={
                  stonexAPIFetching ||
                  customerType === CustomerType.INSTRUCTING_INSTITUTION ||
                  mode == AddOrEditOrView.View
                }
                placeholder='zip_code'
                isRequired
                formRegister={register('postalCode', { required: true })}
                error={errors['postalCode']}
              />
              <CountrySelect
                label='country'
                disabled={
                  stonexAPIFetching ||
                  customerType === CustomerType.INSTRUCTING_INSTITUTION ||
                  mode == AddOrEditOrView.View
                }
                placeholder='country'
                name='country'
                isRequired
                control={control}
                rules={{ required: true }}
                error={errors['country']}
              />
              <>
                <TextInput
                  label='phone'
                  disabled={stonexAPIFetching || mode == AddOrEditOrView.View}
                  placeholder='phone'
                  isRequired
                  formRegister={register('phone', { required: true })}
                  error={errors['phone']}
                />

                <TextInput
                  label='email_id'
                  disabled={stonexAPIFetching || mode == AddOrEditOrView.View}
                  placeholder='email_id'
                  formRegister={register('email', {
                    required: true,
                    pattern: {
                      value: EMAIL_VALIDATION_REGEX,
                      message: 'invalid_email_address',
                    },
                  })}
                  isRequired
                  error={errors['email']}
                />
              </>

              <DropdownInput
                label='settlement_method'
                placeholder='settlement_method'
                disabled={stonexAPIFetching || mode == AddOrEditOrView.View}
                name='settlementMethod'
                isRequired
                control={control}
                options={SETTLEMENT_METHOD_OPTIONS}
                rules={{ required: true }}
                error={errors['settlementMethod']}
              />
              {[CustomerType.ORDERING_INSTITUTION, CustomerType.INSTRUCTING_INSTITUTION].includes(
                customerType,
              ) && (
                <CurrencySelect
                  label='customer_currency'
                  placeholder='customer_currency'
                  name='customerCurrency'
                  isRequired
                  control={control}
                  rules={{ required: true }}
                  disabled={mode == AddOrEditOrView.View}
                  error={errors['customerCurrency']}
                />
              )}

              {customerType === CustomerType.ORDERING_CUSTOMER && (
                <>
                  <TextInput
                    label='customer_identifier'
                    placeholder='customer_identifier'
                    isRequired
                    disabled={mode == AddOrEditOrView.View}
                    formRegister={register('customerIdentifier', { required: true })}
                    error={errors['customerIdentifier']}
                  />
                  <TextInput
                    label='account_number'
                    placeholder='account_number'
                    isRequired
                    disabled={mode == AddOrEditOrView.View}
                    formRegister={register('accountNumber', { required: true })}
                    error={errors['accountNumber']}
                  />
                  <TextInput
                    label='bank_aba'
                    placeholder='bank_aba'
                    isRequired
                    disabled={mode == AddOrEditOrView.View}
                    formRegister={register('bankABA', { required: true })}
                    error={errors['bankABA']}
                  />
                  <TextInput
                    label='bank_type'
                    placeholder='bank_type'
                    isRequired
                    disabled={mode == AddOrEditOrView.View}
                    formRegister={register('bankType', { required: true })}
                    error={errors['bankType']}
                  />
                </>
              )}

              {[CustomerType.ORDERING_CUSTOMER, CustomerType.ORDERING_INSTITUTION].includes(
                customerType,
              ) && (
                <div className='flex flex-col gap-3'>
                  <label htmlFor='doddFrank'>
                    <Translate value='dodd_frank' /> <span className='text-error-1'>*</span>
                  </label>
                  <Controller
                    name='doddFrank'
                    control={control}
                    render={({ field }) => (
                      <InputSwitch
                        disabled={mode == AddOrEditOrView.View}
                        checked={field.value}
                        onChange={(e) => field.onChange(e.value)}
                      />
                    )}
                  />{' '}
                </div>
              )}
            </div>
            {mode !== AddOrEditOrView.View && (
              <div className='flex items-center justify-center mt-10 gap-5'>
                <Button
                  disabled={isLoading}
                  type='submit'
                  severity='info'
                  onClick={() => {
                    if (handleStepperValidation)
                      handleStepperValidation(ClientForms.CustomerDetails, isValid);
                    if (!isValid) {
                      showToast(
                        'error',
                        'client.field.validation.error',
                        'client.field.validation.error.detail',
                      );
                      return;
                    }
                  }}
                >
                  <Translate value='save_and_proceed' />
                </Button>
              </div>
            )}
          </form>
        ) : (
          <div className='flex justify-between mt-4'>
            <Button
              severity='contrast'
              className='!px-14'
              disabled={!visitPermitted?.has(ClientForms.CustomerDetails)}
              onClick={() => {
                if (jumpToEdit) {
                  jumpToEdit(ClientForms.CustomerDetails);
                }
              }}
            >
              <Translate value={visited?.has(ClientForms.CustomerDetails) ? 'edit' : 'start'} />
            </Button>
            {/* <Progress value={progress} currentStep={1} stepLimit={3} /> */}
          </div>
        )}
      </div>
    </>
  );
};

export default CustomerDetails;