import { RadioButton, type RadioButtonProps } from 'primereact/radiobutton';
import { Controller, type Control } from 'react-hook-form';
import { Checkmark } from '@carbon/icons-react';
import { Translate } from '../../i18n/translate';

type Props = RadioButtonProps & {
  label: string;
  subLabel?: string;
  control: Control<any, any>;
  name: string;
};

const RadioCard = ({ label, subLabel, control, name, ...props }: Props) => {
  return (
    <label
      htmlFor={props.id}
      className='flex enabled:cursor-pointer group gap-1 ring-1 ring-neutral-border has-[:checked]:ring-primary-light has-[:checked]:bg-primary-surface-light rounded-xl p-4'
    >
      <div className='grid grid-rows-[auto_1fr] gap-1 group-has-[:checked]:text-primary-dark-content'>
        <Translate value={label} className='text-md-semibold' />
        {subLabel && <Translate value={subLabel} className='text-sm-regular' />}
      </div>
      {control && (
        <Controller
          name={name}
          control={control}
          render={({ field }) => (
            <RadioButton
              className='opacity-0'
              value={field.value}
              checked={field.value === props.value}
              onChange={(e) => field.onChange(e.value)}
              {...props}
            />
          )}
        />
      )}
      <Checkmark
        fill='var(--primary-text-color)'
        className='bg-primary-dark-content rounded-full min-w-4 h-4 p-0.5 opacity-0 group-has-[:checked]:opacity-100'
      />
    </label>
  );
};

export default RadioCard;
