import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { CustomTabView } from '../../../components';
import { PageHeadContext } from '../../../providers/PageHead';
import { AddOrEditOrView, CustomerType } from '../../../types';
import ClientService from '../clientService';
import CustomerDetails from '../CustomerDetails';
import { CustomerOverview } from '../CustomerOverview';
import SiteDetails from '../SiteDetails';
import UserDetails from '../UserDetails';

const ViewClient = () => {
  const [customerOverview, setCustomerOveriew] = useState<CustomerOverviewFormData>();
  const [customerType, setCustomerType] = useState<CustomerType>();
  const [currentEditiCustomer, setCurrentEditiCustomer] = useState<Client>();
  const context = useContext(PageHeadContext);
  const { setPageHeadData } = context;

  const { customerId } = useParams();

  useEffect(() => {
    getCutomerById();
  }, [customerId]);

  const getCutomerById = async () => {
    if (!customerId) {
      return;
    }
    const client = await ClientService.getClientById(customerId);
    if (client) {
      setCustomerType(client.customerType);
      setCurrentEditiCustomer(client);
      setPageHeadData({
        title: client.customerShortName ?? 'ediClient.title',
        description: 'view.client.description',
      });
    }
  };
  const tabs = [
    {
      header: 'customer.details.tab.title',
      content: (
        <>
          <CustomerOverview
            currentEditiCustomer={currentEditiCustomer}
            setCustomerOveriew={setCustomerOveriew}
            setCustomerType={setCustomerType}
            mode={AddOrEditOrView.View}
          />
          <div className='mt-2'></div>
          <CustomerDetails
            customerType={customerType}
            customerOveriew={customerOverview}
            mode={AddOrEditOrView.View}
            currentEditiCustomer={currentEditiCustomer}
          />
        </>
      ),
    },
    {
      header: 'user_details.title',
      content: <UserDetails mode={AddOrEditOrView.View} />,
    },
    {
      header: 'site_details.title',
      content: <SiteDetails mode={AddOrEditOrView.View} />,
    },
  ];

  return <CustomTabView tabs={tabs} initialIndex={0} />;
};

export default ViewClient;
