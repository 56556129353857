import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { Dialog } from 'primereact/dialog';
import React from 'react';
import { TranslateWithValues, translateWithValues } from '../../i18n/translate';
import './Confirm.css';

interface ConfirmProps {
  title: string;
  message: string;
  confirmLabel?: React.ReactNode;
  cancelLabel?: string;
  onConfirm: any;
  onCancel?: () => void;
  visible: boolean;
  className?: string;
  headerIcon?: React.ReactNode;
  singleButton?: string;
  i18nParameters?: { [key: string]: string };
  checkboxLabel?: React.ReactNode; // New prop for checkbox label
  checkboxChecked?: boolean; // New prop for checkbox state
  onCheckboxChange?: (checked: boolean) => void; // New prop for checkbox change event
  confirmButtonSeverify?:
    | 'success'
    | 'secondary'
    | 'warning'
    | 'info'
    | 'danger'
    | 'help'
    | 'contrast';
}
const Confirm = ({
  confirmLabel,
  cancelLabel,
  title,
  message,
  onConfirm,
  onCancel,
  visible,
  className,
  headerIcon,
  singleButton,
  checkboxLabel,
  checkboxChecked,
  onCheckboxChange,
  i18nParameters,
  confirmButtonSeverify = 'info',
}: ConfirmProps) => {
  return (
    <Dialog
      draggable={false}
      className={`rounded-[12px] ${className} confirm-dialog !w-[335px]`}
      visible={visible}
      header={<div className='w-[40px]'>{headerIcon}</div>}
      style={{
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
      }}
      onHide={onCancel || onConfirm}
    >
      <div className='!pb-[20px] rounded-[12px]'>
        <div className='text-mobile-header-bold text-midnightBlue'>
          {<TranslateWithValues value={title} params={i18nParameters} />}
        </div>
        <div className='mt-2 mb-2'>
          {<TranslateWithValues value={message} params={i18nParameters} />}
        </div>

        {/* Conditionally render the checkbox if checkboxLabel is provided */}
        {checkboxLabel && (
          <div className='flex items-center gap-2 mt-3 p-[12px] rounded-[8px] bg-primary-surface-light border-primary-blue-solid border-[1px] mb-2'>
            <Checkbox
              inputId='confirm-checkbox'
              checked={checkboxChecked || false}
              onChange={(e) => onCheckboxChange?.(e.checked || false)}
            />
            <label htmlFor='confirm-checkbox' className='ml-2'>
              {checkboxLabel}
            </label>
          </div>
        )}

        <div className='flex justify-end mt-[46px]'>
          {!singleButton && (
            <Button
              label={translateWithValues(cancelLabel) || 'Cancel'}
              severity='secondary'
              className='p-button-text'
              onClick={onCancel}
            />
          )}
          <Button
            severity={confirmButtonSeverify}
            className='p-button-text ml-1 relative'
            onClick={onConfirm}
          >
            {singleButton
              ? singleButton
              : React.isValidElement(confirmLabel)
                ? confirmLabel
                : confirmLabel
                  ? translateWithValues(confirmLabel)
                  : 'Confirm'}
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export default Confirm;
