import { PrimeReactProvider } from 'primereact/api';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import App from './App';
import I18nProvider from './i18n';
import './index.css';
import reportWebVitals from './reportWebVitals';
import store from './store';
import Custom from './styles/theme';
import './types';

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <I18nProvider locale='en'>
          <PrimeReactProvider value={{ pt: { ...Custom }, unstyled: false }}>
            <App />
          </PrimeReactProvider>
        </I18nProvider>
      </QueryClientProvider>
    </Provider>
  </React.StrictMode>,
);
reportWebVitals();
