import { AxiosResponse } from 'axios';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { CurrencySelect, DropdownInput, TextInput } from '../../../../components';
import { Translate, translateWithValues, TranslateWithValues } from '../../../../i18n/translate';
import { selectCurrentCustomer, sendToast } from '../../../../store/slices/main/mainSlice';
import { FEES_TYPE_OPTIONS } from '../Dashboard/formConstants';
import ManagePricingService from '../managePricingService';
import './../index.css';

const CreateFees = ({
  client,
  visible,
  setCreateFeesToggle,
  editEntity,
  isViewMode,
}: {
  client?: Client;
  visible: boolean;
  editEntity?: Fees;
  setCreateFeesToggle: (visible: boolean) => void;
  isViewMode: boolean;
}) => {
  const {
    handleSubmit,
    register,
    control,
    reset,
    watch,
    getValues,
    formState: { errors },
  } = useForm<CreateFeeFormData>();
  const formRef = useRef(null);
  const currentCustomer = useSelector(selectCurrentCustomer);
  const dispatch = useDispatch();
  const feeType = watch('feeType');

  useEffect(() => {
    if (editEntity) {
      reset(editEntity);
    } else {
      reset({
        ...Object.fromEntries(Object.entries(getValues()).map(([key]) => [key, ''])),
        feeType,
      });
    }
  }, [editEntity, feeType]);

  const getFeeHeader = () => {
    const baseTitle = client ? 'ForClient' : '';
    console.log(isViewMode);
    if (isViewMode) {
      return `viewFeesTitle${baseTitle}`;
    } else if (editEntity) {
      return `editFeesTitle${baseTitle}`;
    } else {
      return `createFeesTitle${baseTitle}`;
    }
  };

  const headerElement = () => (
    <div className='flex justify-between items-center w-full'>
      <div className='flex flex-col gap-1'>
        <div className='text-lg font-semibold text-neutral-1'>
          <TranslateWithValues
            value={getFeeHeader()}
            params={client ? { clientName: client?.customerShortName } : {}}
          />
        </div>
        <div className='text-sm font-regular text-neutral-3'>
          <Translate value='createFeesDescription' className='!text-sm-regular' />
        </div>
      </div>
    </div>
  );

  const onSave = async (data: CreateFeeFormData) => {
    if (currentCustomer?.id) {
      if (!editEntity) {
       const response = await ManagePricingService.createFee(
         client && client.id ? client.id : currentCustomer.id,
         data,
       );
       handleResponse(response);
      } else {
        const response = await ManagePricingService.updateFee(editEntity.id, data);
        handleResponse(response);
      }
    }
  };

  const showToast = (
    severity: 'success' | 'error',
    summary: string,
    detail: string,
    params?: { [key: string]: string },
  ) => {
    dispatch(sendToast({ severity, summary, detail, params }));
  };

  const handleResponse = (response: AxiosResponse) => {
    const { data, status } = response;
    if (status !== 200) {
      showToast(
        'error',
        'error',
        editEntity ? 'fees.updation.error.toast.desc' : 'fees.create.error.toast.desc',
      );
      return;
    } else {
      const { result } = data;
      if (result.success) {
        showToast(
          'success',
          'success',
          editEntity ? 'fee.update.success.toast' : 'fee.success.toast',
        );
        setCreateFeesToggle(false);
      } else {
        const { errors } = result;
        showToast('error', 'error', (errors[0] && errors[0].errorTextCode) ?? '', {
          ...errors[0],
        });
        return;
      }
    }
  };

  const footerContent = (
    <div className='flex justify-end mt-5 gap-2'>
      <Button severity='secondary' onClick={() => setCreateFeesToggle(false)}>
        <Translate value='cancel' />
      </Button>
      <Button
        severity='info'
        onClick={() => {
          if (formRef.current) {
            (formRef.current as HTMLFormElement).dispatchEvent(
              new Event('submit', { cancelable: true, bubbles: true }),
            ); // Manually trigger form submit
          }
        }}
      >
        <Translate value='save' />
      </Button>
    </div>
  );

  const mustBeNonNegativeTooltipMesssage = translateWithValues('must_be_non_negative_number');

  const nonNegativeDecimalValidation = (value: string) => {
    const val = Number(value);
    if (isNaN(val)) {
      return mustBeNonNegativeTooltipMesssage;
    }
    return val >= 0 || mustBeNonNegativeTooltipMesssage;
  };

  return (
    <Dialog
      draggable={false}
      className='w-[30%] scale max-h-[100%] transform scale-100 m-0 !rounded-none full-screen-dialog'
      header={headerElement}
      visible={visible}
      style={{ height: '100vh', position: 'fixed', top: 0, right: 0 }}
      onHide={() => setCreateFeesToggle(false)}
      footer={footerContent}
    >
      {
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        <form onSubmit={handleSubmit(onSave)} ref={formRef}>
          <DropdownInput
            label='feeType'
            placeholder='feeType'
            name='feeType'
            disabled={!!editEntity || isViewMode}
            isRequired
            control={control}
            options={FEES_TYPE_OPTIONS}
            rules={{ required: true }}
            error={errors['feeType']}
          />

          <TextInput
            control={control}
            className='mt-[32px]'
            label='description'
            placeholder='description'
            isRequired
            formRegister={register('description', { required: true })}
            error={errors['description']}
            disabled={isViewMode}
          />

          <CurrencySelect
            includeAll={true}
            control={control}
            className='mt-[32px] w-full'
            name='currency'
            label='currency'
            placeholder='ccy'
            rules={{ required: true }}
            error={errors['currency']}
            isRequired
            disabled={isViewMode}
          />

          <CurrencySelect
            includeAll={true}
            control={control}
            className='mt-[32px] w-full'
            name='feeCurrency'
            label='feeCurrency'
            placeholder='feeCurrency'
            rules={{ required: true }}
            error={errors['feeCurrency']}
            isRequired
            disabled={isViewMode}
          />

          <TextInput
            control={control}
            className='mt-[32px]'
            name='amount'
            label='amount'
            placeholder='amount'
            isRequired
            formRegister={register('amount', {
              required: true,
              validate: nonNegativeDecimalValidation,
            })}
            error={errors['amount']}
            disabled={isViewMode}
          />
        </form>
      }
    </Dialog>
  );
};

export default CreateFees;
