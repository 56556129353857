import { Dropdown } from 'primereact/dropdown';
import { IconField } from 'primereact/iconfield';
import { InputIcon } from 'primereact/inputicon';
import { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { Translate, translateWithValues } from '../../i18n/translate';
import { selectCustomerCurrencies } from '../../store/slices/refdata/refdataSlice';
import ErrorIcon from '../ErrorIcon';
import MultiSelectInput from '../MultiSelect';

interface Currency {
  label: string;
  value: string;
}

interface CurrencyDropdownProps {
  id?: string;
  disabled?: boolean;
  label?: string;
  placeholder?: string;
  ariaDescribedBy?: string;
  isRequired?: boolean;
  className?: string;
  value?: Currency;
  name?: string;
  control?: any;
  error?: any;
  rules?: any;
  includeAll?: boolean;
  isMulti?: boolean;
  multiSelectValue?: string[];
  onChange?: any;
}

const CurrencySelect = ({
  label,
  disabled,
  id,
  isRequired,
  ariaDescribedBy,
  placeholder,
  className,
  value,
  rules,
  name,
  error,
  control,
  includeAll = false,
  isMulti = false,
  multiSelectValue,
  onChange,
}: CurrencyDropdownProps) => {
  const [currencies, setCurrencies] = useState<any>([]);
  const customerCurrencies = useSelector(selectCustomerCurrencies);

  useEffect(() => {
    const _currencies: Currency[] = Array.from(
      new Set(
        customerCurrencies
          .filter((c) => {
            return c.isocode !== 'ALL';
          })
          .map((currency) => currency.isocode),
      ),
    ).map((isocode) => ({
      label: isocode,
      value: isocode,
    }));

    _currencies.sort((a, b) => {
      if (a.value && b.value) {
        return a.value.localeCompare(b.value);
      }
      return 0;
    });

    if (includeAll) {
      _currencies.unshift({
        label: 'ALL',
        value: 'ALL',
      });
    }
    setCurrencies(_currencies);
  }, [customerCurrencies]);

  const selectedCurrencyTemplate = (option: Currency) => {
    if (option) {
      return (
        <div className='flex align-items-center'>
          {option.value != 'ALL' && (
            <img
              alt={option.value ?? ''}
              src={`/images/c_flags_svg/${option.value?.toLocaleLowerCase()}.svg`}
              className='mr-2 w-[20px] h-[20px] rounded-full object-cover'
            />
          )}
          <div>{option.value}</div>
        </div>
      );
    }

    return <span>{<Translate value={placeholder ?? ''} />}</span>;
  };

  const currencyOptionTemplate = (option: Currency) => {
    return (
      <div className='flex align-items-center'>
        {option.value != 'ALL' && (
          <img
            alt={option.value ?? ''}
            src={`/images/c_flags_svg/${option.value?.toLocaleLowerCase()}.svg`}
            className='mr-2 w-[20px] h-[20px] rounded-full object-cover'
          />
        )}
        <div className='overflow-hidden overflow-ellipsis'>{option.value}</div>
      </div>
    );
  };

  const errorMessage =
    error &&
    (error.type === 'required'
      ? `${translateWithValues(label)} ${translateWithValues('is_required')}`
      : error.message);

  return (
    <>
      {!isMulti && (
        <div className={`flex flex-col gap-1 ${className || ''}`}>
          <label htmlFor={id}>
            {translateWithValues(label)} {isRequired ? <span className='text-error-1'>*</span> : ''}
          </label>
          {control && name ? (
            <IconField iconPosition='right'>
              {!isMulti && (
                <Controller
                  name={name}
                  control={control}
                  rules={rules}
                  render={({ field }) => (
                    <Dropdown
                      className='w-full'
                      id={id}
                      tooltip={error && errorMessage}
                      tooltipOptions={{ position: 'bottom' }}
                      value={field.value}
                      onChange={(e) => field.onChange(e.value)}
                      options={currencies}
                      disabled={disabled}
                      optionLabel='label'
                      placeholder={placeholder && translateWithValues(placeholder)}
                      filter
                      valueTemplate={(option: Currency) => selectedCurrencyTemplate(option)}
                      itemTemplate={currencyOptionTemplate}
                      invalid={!!error}
                    />
                  )}
                />
              )}
              {!!error && (
                <InputIcon className='mr-[23px]'>
                  <ErrorIcon isFilled={false} size={16} withBackground={false} />
                </InputIcon>
              )}
            </IconField>
          ) : (
            <IconField iconPosition='right'>
              <Dropdown
                id={id}
                name={name}
                value={value}
                disabled={disabled}
                aria-describedby={ariaDescribedBy}
                placeholder={placeholder && translateWithValues(placeholder)}
                options={currencies}
                optionLabel='label'
                filter
                valueTemplate={selectedCurrencyTemplate}
                itemTemplate={currencyOptionTemplate}
                className='w-full md:w-14rem'
                invalid={!!error}
              />
              {!!error && (
                <InputIcon className='mr-[23px]'>
                  <ErrorIcon isFilled={false} size={16} withBackground={false} />
                </InputIcon>
              )}
            </IconField>
          )}
        </div>
      )}
      {isMulti &&
        (control ? (
          <MultiSelectInput
            label={label}
            control={control}
            disabled={disabled}
            className={`${className}`}
            name={name}
            placeholder={placeholder && translateWithValues(placeholder)}
            rules={{ required: true }}
            error={error}
            isRequired
            options={currencies}
            itemTemplate={currencyOptionTemplate}
          />
        ) : (
          <MultiSelectInput
            value={multiSelectValue}
            options={currencies}
            disabled={disabled}
            itemTemplate={currencyOptionTemplate}
            placeholder={placeholder && translateWithValues(placeholder)}
            className='w-full'
            onChange={onChange}
          />
        ))}
    </>
  );
};

export default CurrencySelect;
