import apiClient from '../../../utils/apiClient';
import { handleApiResponse } from '../../../utils/helper';

const ROOT = '/api/pricing-management/customers';
const MARGINS_ROOT = '/api/pricing-management/margins';
const FEES_ROOT = '/api/pricing-management/fees';
const BASE_ROOT = '/api/pricing-management/';

const ManagePricingService = {
  async createMargin(customerId: number, marginData: any) {
    return apiClient.put(`${ROOT}/${customerId}/margin`, marginData);
  },

  async createFee(customerId: number, feeData: any) {
    return apiClient.put(`${ROOT}/${customerId}/fee`, feeData);
  },

  async getMarginById(marginId: string): Promise<Margin | null> {
    try {
      const response = await apiClient.get(`${MARGINS_ROOT}/${marginId}`);
      return handleApiResponse<Margin | null>(response);
    } catch (error) {
      console.error('Error fetching margin by id:', error);
      return null;
    }
  },

  async updateMargin(marginId: number, marginData: any) {
    return apiClient.post(`${MARGINS_ROOT}/${marginId}`, marginData);
  },

  deleteMargin(marginId: number) {
    return apiClient.delete(`${MARGINS_ROOT}/${marginId}`);
  },

  async getFeeById(feeId: string): Promise<Fees | null> {
    try {
      const response = await apiClient.get(`${FEES_ROOT}/${feeId}`);
      return handleApiResponse<Fees | null>(response);
    } catch (error) {
      console.error('Error fetching fee by id:', error);
      return null;
    }
  },

  async updateFee(feeId: number, feeData: any) {
    return apiClient.post(`${FEES_ROOT}/${feeId}`, feeData);
  },

  async deleteFee(feeId: number) {
    return apiClient.delete(`${FEES_ROOT}/${feeId}`);
  },

  updateFeeStatus(feeId: number, status: Status) {
    return apiClient.post(`${FEES_ROOT}/${feeId}/status`, status, {
      headers: { 'Content-Type': 'application/json-patch+json' },
    });
  },

  updateMarginStatus(marginId: number, status: Status) {
    return apiClient.post(`${MARGINS_ROOT}/${marginId}/status`, status, {
      headers: { 'Content-Type': 'application/json-patch+json' },
    });
  },

  async getCustomerMargins(
    customerId: number,
    page = 0,
    rows = 0,
    payload: { [key: string]: string[] | string },
    sortParams: SortParams,
  ): Promise<MarginResponse | null> {
    const sortValue = sortParams.sortOrder
      ? `&sortBy=${sortParams.sortField}&direction=${sortParams.sortOrder === 1 ? 'asc' : 'desc'}`
      : '';
    try {
      const response = await apiClient.post(
        `${ROOT}/${customerId}/margins?page=${page}&size=${rows}${sortValue}`,
        payload,
      );
      return handleApiResponse<MarginResponse>(response);
    } catch (error) {
      console.error('Error fetching margins for customer:', error);
      return null;
    }
  },

  async getCustomerFees(
    customerId: number,
    page = 0,
    rows = 0,
    payload: { [key: string]: string[] | string },
    sortParams: SortParams,
  ): Promise<FeeResponse | null> {
    const sortValue = sortParams.sortOrder
      ? `&sortBy=${sortParams.sortField}&direction=${sortParams.sortOrder === 1 ? 'asc' : 'desc'}`
      : '';
    try {
      const response = await apiClient.post(
        `${ROOT}/${customerId}/fees?page=${page}&size=${rows}${sortValue}`,
        payload,
      );
      return handleApiResponse<FeeResponse | null>(response);
    } catch (error) {
      console.error('Error fetching fees for customer:', error);
      return null;
    }
  },

  async downloadTableData(customerId: number, payload: FilterFormData, tableName: string) {
    try {
      const response = await apiClient.post(
        `${BASE_ROOT}/${tableName}/customers/${customerId}/download/csv`,
        payload,
        {
          responseType: 'blob',
        },
      );
      const blob = new Blob([response.data], { type: 'text/csv' });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${tableName}_data.csv`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error(`Error fetching margins with id ${customerId}`, error);
      return null;
    }
  },
};

export default ManagePricingService;
