import { TabPanel, TabView } from 'primereact/tabview';
import { useSearchParams } from 'react-router-dom';
import { translateWithValues } from '../../../../i18n/translate';
import ApprovalsTab from './ApprovalsTab';
import './index.scss';

const Dashboard = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const tabIndexes = [
    'PAYMENT',
    'BENEFICIARY_MANAGEMENT',
    'MANAGE_USER',
    'MANAGE_CUSTOMER',
    'MANAGE_APPROVAL_WORKFLOW',
  ];

  const tab = searchParams.get('tab') || 'PAYMENT';
  const tabIndex = Math.max(tabIndexes.findIndex((item) => item === tab) || 0, 0);

  return (
    <div className='approvals'>
      <TabView
        activeIndex={tabIndex}
        onTabChange={(e) => setSearchParams({ tab: tabIndexes[e.index] })}
        className='stone-tabs'
      >
        <TabPanel header={translateWithValues('payments')}>
          <ApprovalsTab feature='PAYMENT' />
        </TabPanel>
        <TabPanel header={translateWithValues('beneficiaries')}>
          <ApprovalsTab feature='BENEFICIARY_MANAGEMENT' />
        </TabPanel>
        <TabPanel header={translateWithValues('users')}>
          <ApprovalsTab feature='MANAGE_USER' />
        </TabPanel>
        <TabPanel header={translateWithValues('customers')}>
          <ApprovalsTab feature='MANAGE_CUSTOMER' />
        </TabPanel>
        <TabPanel header={translateWithValues('compliance')}>
          <ApprovalsTab feature='MANAGE_APPROVAL_WORKFLOW' />
        </TabPanel>
      </TabView>
    </div>
  );
};

export default Dashboard;
